import { useCallback, useEffect, useState } from 'react'
import { Tags } from 'lucide-react';
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover';
import { Button } from './ui/button';
import ReactSelect from './ui/react-select/react-select';
import { cn } from '../lib/utils';
import TagsBadge from './TagsBadge';

const TagsSelect = ({ value, tagsOptions, disabled, onChange, isLoading }) => {
    console.log(value, tagsOptions)
    const [selectedTags, setSelectedTags] = useState([]);

    const resetSelectedTags = useCallback(() => {
        if (value) {
            setSelectedTags(value.map(tag => tagsOptions.find(option => option.value === tag)) || [])
        } else {
            setSelectedTags([])
        }
    }, [value, tagsOptions]);

    useEffect(() => {
        resetSelectedTags();
    }, [value, tagsOptions, resetSelectedTags])

    const handleSelectChange = useCallback((value) => {
        setSelectedTags(value)
    }, []);

    const handleSubmit = () => {
        onChange(selectedTags.map(tag => tag.value))
    }

    return (
        <Popover onOpenChange={(open) => {
            if (!open || open) {
                resetSelectedTags();
            }
        }}>
            <PopoverTrigger asChild disabled={disabled} className={cn(disabled && 'disabled:tw-opacity-100')}>
                <Button  variant="ghost" className="tw-p-1 tw-group tw-whitespace-nowrap tw-w-full" isLoading={isLoading} absoluteLoader={true}>
                    {value && value?.length > 0 ? (
                        <TagsBadge
                        tagsOptions={tagsOptions}
                        tagsList={value}
                    />
                    ) : (
                        <span className='tw-inline-flex tw-gap-2 tw-items-center tw-py-1 tw-px-2'>
                            <small className='tw-text-base'>-</small>
                            <Tags className="tw-size-4 tw-opacity-0 transition-all group-hover:tw-opacity-100" />
                        </span>
                    )}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="tw-min-w-48 tw-p-4" align="start" >
                <div className="space-y-2">
                    <ReactSelect
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        isClearable
                        isMulti
                        options={tagsOptions}
                        value={selectedTags}
                        onChange={handleSelectChange}
                        placeholder="Select Account"
                    />
                    <Button className="tw-mt-2" onClick={handleSubmit} variant={"gradient"} isLoading={isLoading}>
                        Submit
                    </Button>
                </div>
            </PopoverContent>
        </Popover>
    )
}

export default TagsSelect