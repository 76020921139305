import React, { useEffect, useMemo, useRef, useState } from 'react'
import { getCustomDashboadList, getParticularSlaveTemperatureAnalysis, getPowerSourceTotalGSM, getSlaveTempratureanalysis, getSlaveTempratureRangeDetails, onGetConsumerIncolumeName, onGetSlaveConsumerIncolumeName, updateDashboardAddCustomCard } from '../../Common/Utils';
import { Card, CardContent, CardFooter, CardHeader, CustomeCardHeader, TaskFilterButton } from '../../Common/ui/card';
import ReactApexChart from "react-apexcharts";
import loaderGif from '../../assets/img/loading_chart.gif'
import { Modal, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { colorStyles } from '../../Common/common';
import { getPieChartOptions, coustomeDashboard as card_json, sum } from './utils';
import { DraggableHelper } from '../../Common/ui/DraggableHelper';
import MoreOptionButton from './components/MoreOptionButton';
import { CaptureImage } from '../../Common/Download/ChartDownload';
import { useMutation } from '@tanstack/react-query';
import { Popover, PopoverContent, PopoverTrigger } from '../../Common/ui/popover';
import { Button } from '../../Common/ui/button';
import { Loader } from 'lucide-react';
import { toast } from 'react-toastify';
import swal from 'sweetalert2';
import moment from 'moment';

export default function EnvironmentDashboard(props) {

  // T-TD14729 add
  const card1Ref = useRef();
  // T-TD14729 add
  const { permision, setSubActiveTab, activeTab, DashTabDetailById = {}, subActiveTab, onChnageCardDetsiles, DashTabs, setDashTabs } = props;
  // T-TD14729 add
  const view_json = JSON.parse(DashTabDetailById?.view_json || '{}')

  const [TempRange, setTempRange] = useState({ series: [], category: [], code: [], isLoading: true, isError: false })
  const [seriesTemp, setseriesTemp] = useState([])
  const [TableData, setTableDataInApi] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ModelId, setModelId] = useState('')
  const [ModalLoad, setModalLoad] = useState(false)
  const [tempAnalysis, setTempAnalysis] = useState({})
  const [Profile, setProfile] = useState({ account: "", ga: "", circle: "", division: "", subdivision: "", area: "", ins_no: [] });

  const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
  let colors = ['#008ffb', '#00e396', '#feb019', '#ff4560', '#775dcf', '#FF7700', '#554994', "#D36B00", "#0096FF"];
  const [isrange, setIsRange] = useState(false)
  const [isDashModal, setisDashModal] = useState(false);    // T-TD14729 add
  const [Modal_card_name, setModal_card_name] = useState('')   // T-TD14729 add
  const [card_id, setcard_id] = useState('')  // T-TD14729 add
  const [CardFilter, setCardFilter] = useState({})  // T-TD14729 add
  const [CardBar, setCardBar] = useState([])  // T-TD14729 add

  const ongetPowerSourceTotalGSM = async (info) => {
    setTempRange((prv) => ({ ...prv, series: [], category: [], isLoading: true, isError: false }))
    const data = await getSlaveTempratureRangeDetails(info);
    if (data.success === true) {
      const chartData = data?.data?.data;
      setTempRange({series:chartData.map(item=>item?.counts),category:chartData.map(item=>item?.Ranges?item?.Ranges+`(${item?.counts})`:'null'+`(${item?.counts})`)});
    } else {
      setTempRange((prv) => ({ ...prv, series: [], category: [], isLoading: false, isError: true }))
      console.log("something went warong");
    }
  }

  const ongetSlaveTempratureanalysis = async (info) => {
    setTempAnalysis({})
    const data = await getSlaveTempratureanalysis(info);
    if (data.success === true) {
      setTempAnalysis(data?.data?.result || {})
    } else {
      console.log("something went warong");
    }
  }

  // // T-TD14729 add
  // const getCosutomDashboardListMutation = useMutation({
  //   mutationFn: getCustomDashboadList,
  //   onSuccess: (res) => {
  //     const dashtab = res?.data?.data?.filter((item) => (permision?.ROLES_OTHER_PERMISSIONS?.CUSTOM_DASHBOARD?.dashboard_view?.includes(item?.unique_id) || userInfo?.role == 'ROLE1650614500708') || true)?.map((item) => ({ title: item?.name, value: item?.unique_id, id: item?.unique_id, ...item }))
  //     setDashTabs(dashtab || [])
  //   },
  //   onError: (error) => {
  //     console.log(error)
  //   }
  // })
  // // T-TD14729 add
  // useEffect(() => {
  //   getCosutomDashboardListMutation.mutate()
  // }, [])

  // T-TD14729 update
  useEffect(() => {
    if (permision) {
      const infoRolesObj = { ROLES: userInfo?.role, Profile: { CATEGORY0: permision?.roles_permission_category0?.CATEGORY0_view, CATEGORY1: permision?.roles_permission_category1?.CATEGORY1_view, CATEGORY2: permision?.roles_permission_category2?.CATEGORY2_view, CATEGORY3: permision?.roles_permission_category3?.CATEGORY3_view, CATEGORY4: permision?.roles_permission_category4?.CATEGORY4_view, CATEGORY5: permision?.roles_permission_category5?.CATEGORY5_view } }

      if (view_json[card_json[1].U_ID]?.display == true || activeTab !== 'Custom Dashboard') {
        ongetPowerSourceTotalGSM(infoRolesObj)
      }

      if (view_json[card_json[2].U_ID]?.display == true || activeTab !== 'Custom Dashboard') {
        ongetSlaveTempratureanalysis(infoRolesObj)
      }
    }
  }, [permision, subActiveTab])

  const GetConsumerIncolumeName = async (isbetween, col, colname, value, CARD_ID) => {
    setIsModalVisible(true)
    setModalLoad(true)
    setModelId(`${colname}`)
    let obj = {}
    obj = { isbetween: isbetween, colName: col, value: value, CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ins_no: Profile.ins_no, ROLES: userInfo?.role, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' && Profile?.ins_no?.length == 0 ? false : true, Profile: { CATEGORY0: permision?.roles_permission_category0?.CATEGORY0_view, CATEGORY1: permision?.roles_permission_category1?.CATEGORY1_view, CATEGORY2: permision?.roles_permission_category2?.CATEGORY2_view, CATEGORY3: permision?.roles_permission_category3?.CATEGORY3_view, CATEGORY4: permision?.roles_permission_category4?.CATEGORY4_view, CATEGORY5: permision?.roles_permission_category5?.CATEGORY5_view } }
    const data = await onGetSlaveConsumerIncolumeName(obj)
    if (data.success === true) {
      let tempData = data?.data?.data.map((item) => ({ ...item, MMD_STATUS_PWR_SOURCE: item?.HEARTBEAT_LAST_DATA ? JSON.parse(item?.HEARTBEAT_LAST_DATA || '{}').MMD_STATUS_PWR_SOURCE : null, ALERT_MMD_STATUS_PWR_SOURCE: item?.ALERT_DISABLE ? JSON.parse(item?.ALERT_DISABLE || '{}').MMD_STATUS_PWR_SOURCE : null }));
      setTableDataInApi(tempData)
      setModalLoad(false)
    } else {
      console.log('somthing want wrong')
      setModalLoad(false)

    }
  }

  const ongetParticularSlaveTemperatureAnalysis = async ({ condition, colname }) => {
    setIsModalVisible(true)
    setModalLoad(true)
    setModelId(`${colname}`)
    setIsRange(true)
    const infoRolesObj = { ROLES: userInfo?.role, Profile: { CATEGORY0: permision?.roles_permission_category0?.CATEGORY0_view, CATEGORY1: permision?.roles_permission_category1?.CATEGORY1_view, CATEGORY2: permision?.roles_permission_category2?.CATEGORY2_view, CATEGORY3: permision?.roles_permission_category3?.CATEGORY3_view, CATEGORY4: permision?.roles_permission_category4?.CATEGORY4_view, CATEGORY5: permision?.roles_permission_category5?.CATEGORY5_view } }
    const data = await getParticularSlaveTemperatureAnalysis({ condition, ...infoRolesObj });
    if (data.success === true) {
      let tempData = data?.data?.data.map((item) => ({ ...item, MMD_STATUS_PWR_SOURCE: item?.HEARTBEAT_LAST_DATA ? JSON.parse(item?.HEARTBEAT_LAST_DATA || '{}').MMD_STATUS_PWR_SOURCE : null, ALERT_MMD_STATUS_PWR_SOURCE: item?.ALERT_DISABLE ? JSON.parse(item?.ALERT_DISABLE || '{}').MMD_STATUS_PWR_SOURCE : null }));
      setTableDataInApi(tempData)
      setModalLoad(false)
    } else {
      console.log("something went warong");
      setModalLoad(false)
    }
  }

  var options1 = getPieChartOptions(
    350,
    TempRange.category,
    colors,
    function (event, chartContext, config) {
      var date_index = config.dataPointIndex;
      
      GetConsumerIncolumeName(false, '', TempRange.category[date_index]?.split('(')[0], TempRange.category[date_index]?.split('(')[0] !== 'null' && TempRange.category[date_index] != 'null' && TempRange.category[date_index] ? `(JSON_VALUE(temp_config,'$.MIN')=${TempRange.category[date_index].split('-')[0]} AND  JSON_VALUE(temp_config,'$.MAX')=${TempRange.category[date_index].split('-')[1].split('(')[0]})` : `temp_config is null or (JSON_VALUE(temp_config,'$.MIN') is null AND JSON_VALUE(temp_config,'$.MAX') is null)`)
    },
    function (val, opt) {
      return TempRange.series[opt.seriesIndex];
    }
  );

  const handleCancel = () => {
    setIsModalVisible(false);
    setTableDataInApi([])
    setIsRange(false)
  };

  const onHandaleViewGSM = (id) => {
    window.open(`/view_existing_consumer_gsm/${id}`, '_blank')
  }

  const onHandaleViewSlave = (id, slave) => {
    window.open(`/view_existing_consumer_gsm/${id}?active_tab=slave&slave_id=${slave}/`, '_blank')
  }

  const lookupForTemp = useMemo(() => [
    {
      label: 'Normal Temperature',
      condition: 'WITHIN_RANGE',
      value: tempAnalysis?.withinRange || 0,
    },
    {
      label: 'High Temperature',
      condition: 'ABOVE_MAX',
      value: tempAnalysis?.aboveMax || 0,
    },
    {
      label: 'Low Temperature',
      condition: 'BELOW_MIN',
      value: tempAnalysis?.belowMin || 0,
    },
    {
      label: 'Slave Communication Error',
      condition: 'NULL_DATA',
      value: tempAnalysis?.nullCount || 0,
    },
    {
      label: 'No Recent Data (Within 15 mins)',
      condition: 'DATA_NOT_FOUND',
      value: tempAnalysis?.dataNotFoundIn15Min || 0,
    }
  ], [tempAnalysis]);

  // T-TD14729 add
  const onHandaleChange = (e, data, id) => {
    if (e.target.checked) {
      var dash_info = DashTabs;
      var obj = JSON.parse(data?.view_json || '{}')
      obj[card_id] = { ...obj[card_id] || {}, position: Object.keys(obj).length + 1 || 1, display: true, bar_pemission: [...CardBar.map(item => item?.name)] };
      dash_info[id].view_json = JSON.stringify(obj)
      setDashTabs([...dash_info])
    } else {
      var dash_info = DashTabs
      var obj = JSON.parse(data?.view_json || '{}')
      delete obj[card_id];
      dash_info[id].view_json = JSON.stringify(obj)
      setDashTabs([...dash_info])
    }
  }

  // T-TD14729 add
  const onHandaleBarChange = (e, data, id) => {
    if (e.target.checked) {
      var dash_info = DashTabs;
      var obj = JSON.parse(data?.view_json || '{}')
      obj[card_id] = { ...obj[card_id], bar_pemission: [...obj[card_id]?.bar_pemission || [], e.target.name] };
      dash_info[id].view_json = JSON.stringify(obj)
      setDashTabs([...dash_info])
    } else {
      var dash_info = DashTabs
      var obj = JSON.parse(data?.view_json || '{}')
      obj[card_id] = { ...obj[card_id], bar_pemission: obj[card_id]?.bar_pemission?.filter((item) => (item !== e.target.name)) }
      dash_info[id].view_json = JSON.stringify(obj)
      setDashTabs([...dash_info])
    }
  }
  // T-TD14729 add
  const UpdateCustomDasboardMutation = useMutation({
    mutationFn: updateDashboardAddCustomCard,
    onSuccess: (res, variables) => {
      if (variables?.isremove) {
        // console.log(variables?.DashTabs, DashTabs, subActiveTab)
        // setDashTabs(variables?.DashTabs);
        toast.success('Remove card in Dashboard successfully')
        setDashTabs((prevTabs) =>
          prevTabs.map((tab) =>
            tab?.value === subActiveTab
              ?variables?.DashTabs[0]
              : tab
          )
        );
        // onChnageCardDetsiles()
      } else if (variables?.isPostionChange) {
        toast.success('Position of card change in this Dashboard successfully')
        setDashTabs(variables?.DashTabs);
      } else {
        toast.success('Added card in Dashboard successfully')
        setisDashModal(false)
        setcard_id('')
        setCardBar([])
      }
    },
    onError: (error) => {
      toast.error('Server Issue')
    }
  })
  // T-TD14729 add
  const UpdateCustomDasboardInCardDelete = async (card_id) => {
    swal.fire({
      title: "Are you sure?",
      text: "card remove in this dashboard",
      icon: "warning",
      showCancelButton: true,
    }).then(async (willDelete) => {
      if (willDelete.isConfirmed) {
        let data_info = DashTabDetailById
        var viewJson = JSON.parse(data_info?.view_json || '{}');
        if (viewJson[card_id]) {
          delete viewJson[card_id];
          data_info.view_json = JSON.stringify(viewJson);
        }
        UpdateCustomDasboardMutation.mutate({ DashTabs: [data_info], isremove: true })
      }
    })
  }
  // T-TD14729 add
  const UpdateCustomDasboardInCardPosition = async (viewJson, draggedCard) => {
    swal.fire({
      title: "Are you sure?",
      text: `Are you sure the position of the ${card_json.filter((item) => item?.U_ID == draggedCard)[0].CARD_NAME} card has changed in this dashboard?`,
      icon: "warning",
      showCancelButton: true,
    }).then(async (willDelete) => {
      if (willDelete.isConfirmed) {
        var updatedDashTabs = { ...DashTabDetailById };
        updatedDashTabs.view_json = JSON.stringify(viewJson)
        UpdateCustomDasboardMutation.mutate({ DashTabs: [updatedDashTabs], isPostionChange: true })
      }
    })
  }
  // T-TD14729 add
  const onDrop = (draggedId, targetId, draggedCard, targetCard) => {
    // Clone the DashTabs object to avoid direct state mutation
    const updatedDashTabs = { ...DashTabDetailById };
    // Parse the view_json
    // let viewJson ={}
    const viewJson = Object.entries(JSON.parse(updatedDashTabs?.view_json || "{}")).reduce((acc, [key, value], index) => {
      acc[key] = { ...value, position: value?.position || index + 1 };
      return acc;
    }, {});


    // Check for invalid cards
    if (!draggedCard || !targetCard || !viewJson[draggedCard] || !viewJson[targetCard]) {
      console.error("Invalid card IDs. Exiting function.");
      return;
    }

    // Sort the keys of viewJson based on the 'position' property
    const sortedKeys = Object.keys(viewJson).sort((a, b) => viewJson[a].position - viewJson[b].position);
    // Update the position of the dragged card
    const oldPosition = viewJson[draggedCard].position;
    const newPosition = parseInt(targetId);
    viewJson[draggedCard].position = newPosition;

    // Determine the direction of the drag
    const dragDirection = newPosition < oldPosition ? 1 : -1;
    const start = Math.min(oldPosition, newPosition);
    const end = Math.max(oldPosition, newPosition);

    // Update positions of cards between start and end
    sortedKeys.forEach((currentCard, index) => {
      if (currentCard === draggedCard) return;
      const currentPosition = viewJson[currentCard].position;
      if (currentPosition >= start && currentPosition <= end) {
        viewJson[currentCard].position = (viewJson[currentCard].position) + dragDirection;
      }
    });
    UpdateCustomDasboardInCardPosition(viewJson, draggedCard);
  };

  return (
    <>
      <Modal title={<div className="d-flex"><h5 >{ModelId}</h5>{TableData.length > 0 ? <span className='btn btn-primary text-white ml-2' ><i className='fas fa-download'></i></span> : ''}{TableData.length > 0 ? <Link to={{ pathname: `All-GSM`, state: { detail: TableData.map((data) => (data?.UNIQUE_ID)), title: ModelId } }}><span className='btn btn-primary text-white ml-2'><i className='fas fa-up-right-from-square'></i></span></Link> : ''}</div>} maskClosable={false} className="my-modal-class" visible={isModalVisible} footer='' onCancel={handleCancel} width={1000} >
        <div>
          <div className="modal-table">
            {ModalLoad ? (
              <div className="example">
                <Spin size="large" />
              </div>
            ) : <table className="table border text-center table-borderless" id="table-1">
              <thead style={{ position: 'sticky', top: '0', background: '#fff' }}>
                <tr>
                  <th className="text-center">SR NO.</th>
                  <th >Company/Client Name</th>
                  <th >Slave Name</th>
                  <th>Slave Address</th>
                  {isrange?<><th>Temperature Range</th>
                  <th>Temperature value</th>
                  <th>Last Seen</th></>:""}
                </tr>
              </thead>
              <tbody id="tbody">
                <>
                  {TableData.length > 0 ?
                    TableData.map((data, id) => (
                      <tr style={{ height: "4rem", cursor: 'pointer' }} className="trheigth even:tw-bg-gray-100 hover:tw-bg-[#D8D8D8]" key={id} >
                        <td className="text-center" onClick={() => onHandaleViewSlave(data?.consumer_unique_id, data?.unique_id)} >{id + 1}</td>
                        <td className='tw-cursor-pointer' onClick={() => onHandaleViewGSM(data?.consumer_unique_id)}   >{data?.address_compony_name}</td>
                        <td className='tw-cursor-pointer' onClick={() => onHandaleViewSlave(data?.consumer_unique_id, data?.unique_id)} >{data?.feeder_name}</td>
                        <td>{data?.slave_address || " - "}</td>
                        {isrange?
                        <><td>{data?.temp_config?JSON.parse(data?.temp_config||'{}')?.MIN+'-'+JSON.parse(data?.temp_config||'{}')?.MAX:'-'}</td>
                        <td>{data?.slave_temp || " - "}</td>
                        <td><div className="badge badge-success badge-shadow text-white">{data?.instantaneous_ts ? moment.utc(data?.instantaneous_ts).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : ''}</div> </td></>:''}
                      </tr>
                    )) : <tr></tr>}
                </>
              </tbody>
            </table>}
            {TableData.length == 0 && !ModalLoad && <h4 className="text-center">No Data Found</h4>}

          </div>
        </div>
      </Modal>

      {/* // T-TD14729 add */}
      <Modal
        title={"Custom Dashboard permission add " + `(${Modal_card_name})`}
        visible={isDashModal}
        footer=''
        onCancel={() => {
          setisDashModal(false)
          setcard_id('')
          setCardBar([])
        }}
        width={1000}
        height={300}
        style={{
          borderRadius: '12px',
          // overflow: 'hidden',
          padding: "0px"
        }}
      >
        <div className="modal-table">
          <table className="table border table-borderless text-center" id="table-1">
            <thead style={{ position: 'sticky', top: '0', background: '#fff' }}>
              <tr>
                <th className="text-center">Dashboard Name</th>
                <th className="text-center">Add Card</th>
                <th className="text-center">Card Other Permission</th>
              </tr>
            </thead>
            <tbody id="tbody">
              <>
                {DashTabs.length > 0 ?
                  DashTabs.map((data, id) => (
                    <tr key={data?.value} >
                      <td>{data?.name}</td>
                      <td><input type="checkbox" className="chk_MSSQL1" name="checkAll" onChange={(e) => { onHandaleChange(e, data, id) }} checked={JSON.parse(data?.view_json || "{}")[card_id]?.display || false} /></td>

                      <Popover>
                        <PopoverTrigger>
                          <th className='tw-cursor-pointer tw-text-blue-600'>More permission</th>
                        </PopoverTrigger>
                        <PopoverContent showArrow={true} className='tw-z-[1030] tw-w-auto' >
                          <table className="table table-borderless border">
                            <thead className='border leading-8' style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
                              <tr >
                                <th scope="col" className="thalignleft tw-font-normal text-start" style={{ width: "40%" }}>Lable</th>
                                <th scope="col" className="thalign tw-font-normal text-start" style={{ width: "20%" }}>View</th>
                              </tr>
                            </thead>
                            <tbody className='leading-8 ' >
                              {CardBar?.map((perm, index) => (
                                <tr key={index} >
                                  <th scope='row' className='text-start tw-font-normal' style={{ height: "40px" }}>
                                    {perm?.name}
                                  </th>
                                  <th className=' text-start font-normal' style={{ height: "40px" }}>
                                    <input className='' type={"checkbox"} name={perm?.name} id={perm?.name}
                                      onChange={(e) => (onHandaleBarChange(e, data, id))} checked={JSON.parse(data?.view_json || "{}")[card_id]?.bar_pemission?.includes(perm?.name)}></input>
                                  </th>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </PopoverContent>
                      </Popover>
                    </tr>
                  )) : <tr></tr>}
              </>
            </tbody>
          </table>
          <div className="">
            <Button variant='indigo' type='button' disabled={UpdateCustomDasboardMutation?.isPending} className='!tw-text-xs !tw-font-bold mr-2 tw-bg-red-500 hover:tw-bg-red-600'
              onClick={() => UpdateCustomDasboardMutation.mutate({ DashTabs })}>
              {UpdateCustomDasboardMutation?.isPending ? <Loader size={14} className='tw-animate-spin !tw-text-base' /> : 'Submit'}
            </Button>
          </div>
        </div>
      </Modal>

      {/* <div className='tw-mt-5' > */}

      {/* <div className='tw-grid tw-gap-x-6 tw-gap-y-4 tw-grid-cols-12' > */}
      {/* // T-TD14729 update */}
      {(view_json?.[card_json[1].U_ID]?.display === true || activeTab !== 'Custom Dashboard') &&
        (<DraggableHelper isOrderActive={activeTab == 'Custom Dashboard'} onDrop={onDrop} card={card_json[1].U_ID} id={view_json[card_json[1].U_ID]?.position || 2} className={`md:tw-col-span-6 tw-col-span-12`}>
          <Card className=' border-0 border-top !tw-border-t-2 !tw-border-t-binfo '>
            <CustomeCardHeader
              IsDrag={activeTab == 'Custom Dashboard'}
              title={card_json[1].CARD_NAME}
              className="tw-flex tw-justify-between tw-items-start tw-flex-row tw-border-b"
              tooltip={`Find Data In globle_slave_list (temp_config column name)`}
            >
              <MoreOptionButton
                captureImage={(e) => CaptureImage(card1Ref, e, card_json[1].CARD_NAME)}
                activeTab={activeTab}
                hasAddCardPermission={permision?.roles_other_permission?.GSM_CUSTOM_DASHBOARD?.card_add_in_dashboard === true ||
                  userInfo?.role === 'ROLE1650614500708'}
                onAddCard={() => {
                  setisDashModal(true);
                  setModal_card_name(card_json[1].CARD_NAME);
                  setCardBar([]);
                  setcard_id(card_json[1].U_ID);
                }}
                onRemoveCard={() => UpdateCustomDasboardInCardDelete(card_json[1].U_ID)}
              />

            </CustomeCardHeader>
            <CardContent>
              <div
                ref={card1Ref}
                className="card-body"
                style={{ position: "relative", paddingLeft: "0px", paddingRight: "0px", height: "395px" }}>
                {
                  TempRange?.isLoading ? <div className="d-flex tw-items-center tw-justify-center tw-h-full" ><img className="loader_center text-center" src={loaderGif} /></div> :
                    TempRange?.isError ? <div className="d-flex tw-font-bold tw-text-sm tw-items-center tw-justify-center tw-h-full " >Somthing Went wrong</div> : TempRange?.series?.length > 0 && TempRange?.category?.length > 0 ? <ReactApexChart options={options1} type="pie" series={TempRange?.series} height={options1.chart.height} /> : <div className="d-flex tw-font-bold tw-text-sm tw-items-center tw-justify-center tw-h-full" >Data Not Available</div>}
              </div>

              <CardFooter className='tw-flex tw-justify-between tw-px-3 !tw-pb-0' >
                <p className='!tw-m-0'>Total Count : {sum(TempRange?.series)} </p>
                <p className='!tw-m-0'>{'Pie chart(Dynamic)'} </p>
              </CardFooter>
            </CardContent>
          </Card>
        </DraggableHelper>)}

      {/* // T-TD14729 update */}
      {(view_json?.[card_json[2].U_ID]?.display === true || activeTab !== 'Custom Dashboard') &&
        (<DraggableHelper isOrderActive={activeTab == 'Custom Dashboard'} onDrop={onDrop} card={card_json[2].U_ID} id={view_json[card_json[2].U_ID]?.position || 3} className={`md:tw-col-span-6 tw-col-span-12`}>
          <Card className=' border-0 border-top !tw-border-t-2 !tw-border-t-binfo ' >
            <CustomeCardHeader
              IsDrag={activeTab == 'Custom Dashboard'}
              title={card_json[2].CARD_NAME}
              className="tw-flex tw-justify-between tw-items-start tw-flex-row tw-border-b"
              tooltip={`Find Data In slave_temperature_data`}
            >
              <MoreOptionButton
                activeTab={activeTab}
                 hasAddCardPermission={permision?.roles_other_permission?.GSM_CUSTOM_DASHBOARD?.card_add_in_dashboard === true ||
                  userInfo?.role === 'ROLE1650614500708'}
                onAddCard={() => {
                  setisDashModal(true);
                  setModal_card_name(card_json[2].CARD_NAME);
                  setCardBar([]);
                  setcard_id(card_json[2].U_ID);
                }}
                onRemoveCard={() => UpdateCustomDasboardInCardDelete(card_json[2].U_ID)}
              />

            </CustomeCardHeader>
            <CardContent className='tw-h-[445px]' >
              {lookupForTemp.map(({ label, value, condition }, index) => (
                <TaskFilterButton
                  key={index}
                  label={label}
                  count={value}
                  onClick={() => ongetParticularSlaveTemperatureAnalysis({ condition, colname: label })}
                  mainStyle={colorStyles[index]?.mainStyle}
                  countStyle={colorStyles[index]?.countStyle}
                />
              ))}
            </CardContent>
          </Card>
        </DraggableHelper>)}

      {/* </div> */}
      {/* // </div> */}
    </>
  )
}
