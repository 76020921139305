import React, { useEffect } from 'react'
import { Edit, Star } from 'lucide-react'
import { Button } from '../../../Common/ui/button'
import { cn } from '../../../lib/utils'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../Common/ui/select';
import DotLoader from '../../../Common/Loaders/DotLoader'

export default function TabsPopover({
    dataArray = [],
    selectedCard,
    isEdit = false,
    setSelectedCard,
    handleTabModel,
    isEditPermission = false,
    isAddPermission = false,
    setCurrentCoustomeTab,
    currentCoustomeTab,
    isLoading = false,
}) {

    return (
        <div className="tw-w-[550px] tw-p-0  ">
            <div className="">
                {(isEdit && isAddPermission) && (
                    <div className='tw-flex tw-justify-end tw-items-center tw-w-full tw-mb-2 tw-pb-2 border-bottom-dahsed tw-pr-1' >
                        {/* <div>
                            {
                                selectorArray?.length > 0 && (
                                    <Select disabled={isLoading} onValueChange={(value) => { 
                                        setCurrentCoustomeTab(value)
                                        setSelectedCard(null)
                                     }} value={currentCoustomeTab}>
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select a view" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {
                                                selectorArray?.map((v) => {
                                                    if (v?.value !== 'Electrical_parameter') {
                                                        return <SelectItem key={v?.title} value={v?.value}>{v?.title}</SelectItem>
                                                    }
                                                })
                                            }

                                        </SelectContent>
                                    </Select>
                                )
                            }
                        </div> */}
                        <Button
                            className='!tw-text-xs '
                            variant='indigo'
                            size='sm'
                            onClick={(e) => {
                                e.stopPropagation()
                                handleTabModel()
                            }}
                        >
                            + Add
                        </Button>
                    </div>
                )
                }
                {
                    isLoading ? <div className='tw-h-10 tw-flex tw-items-center tw-justify-center' ><DotLoader /></div> :
                        dataArray?.length === 0 ? <div className='tw-h-40 tw-flex tw-items-center tw-justify-center tw-text-lg tw-font-semibold' >No Dashboard Available</div> :
                            <div className={cn("tw-grid tw-grid-cols-2 tw-gap-4")}>
                                {dataArray.map((component) => (
                                    <div
                                        key={component?.value}
                                        className={cn('tw-rounded-lg !tw-border tw-border-solid tw-p-2 tw-cursor-pointer tw-transition-all tw-duration-200 tw-relative  !tw-border-gray-200 hover:!tw-border-indigo-300 hover:!tw-bg-indigo-50', selectedCard === component?.value && '!tw-border-indigo-500 !tw-bg-indigo-50', isEdit && 'tw-flex tw-items-center tw-gap-x-1')}
                                        onClick={() => setSelectedCard(component?.value)}
                                    >
                                        <h3 className={cn('tw-text-sm tw-font-semibold !tw-my-0 tw-text-gray-900 tw-flex-1', selectedCard === component?.value && 'tw-text-indigo-700')}>
                                            {component?.title}
                                        </h3>
                                        {/* <p className="tw-text-sm tw-text-gray-500 tw-mb-0">
                                {component?.description}
                            </p> */}
                                        {/* {
                                            isEdit && (
                                                <Button
                                                    disabled={isLoading}
                                                    border="indigo"
                                                    variant='outline'
                                                    size='icon'
                                                    className='tw-bg-transparent tw-px-0'
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        // handleTabModel(component?.value)
                                                    }}
                                                >
                                                    <Star size={16} />
                                                </Button>
                                            )
                                        } */}

                                        {(isEdit && isEditPermission) && (
                                            <Button
                                                disabled={isLoading}
                                                border="indigo"
                                                variant='outline'
                                                size='icon'
                                                className='tw-bg-transparent tw-px-0'
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    handleTabModel(component?.value)
                                                }}
                                            >
                                                <Edit size={16} />
                                            </Button>
                                        )}
                                    </div>
                                ))}
                            </div>

                }

            </div>

        </div>
    )
}
