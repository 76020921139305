
import { Chip } from './ui/chip'

const TagsBadge = ({ tagsOptions, tagsList, lableClassName = 'max-w-[100px]' }) => {
    const tags = tagsOptions.filter((tag) => tagsList?.includes(tag.value))

    return (
        <div className='tw-flex tw-gap-1 tw-flex-wrap tw-font-normal'>
            {tags.map((tag, index) => {
                return (
                    <Chip
                        key={tag}
                        className="tw-gap-1"
                        variant="light"
                        color="gray"
                        radius="md"
                        size="sm"
                        border="none"
                    >
                        {tag.label || 'Unknown'}
                    </Chip>
                )
            })}
        </div >
    )
}

export default TagsBadge