import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card } from '../../Common/ui/card'
import { getAllCategoryTableData, getCustomDashboadList, getRolesById, InsertGsmCustomDashboard, UpdateGsmCustomDashboard } from '../../Common/Utils';
import DropdownSelecter from './components/DropdownSelector';
import TabsPopover from './components/TabsPopover';
import GoogleLoader from '../../Common/Loaders/GoogleLoader';
import ModemDashboard from './ModemDashboard';
import IOTDashboard from './Dashboard';
import EnvironmentDashboard from './EnvironmentDashboard';
import { useMutation } from '@tanstack/react-query';
import CustomTabModal from './components/customTabModal';
import { toast } from 'react-toastify';

const staticTabsDataArrtay = [
    {
        title: "Modem Dashboard*",
        value: "Modem"
    },
    {
        title: "Environmental parameter Dashboard*",
        value: "Environmental_parameter"
    },
    {
        title: "Electrical parameter Dashboard*",
        value: "Electrical_parameter"
    }
]

export default function DashboardIndex() {

    const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
    const [tabLoader, setTabLoader] = useState(true);
    const [permission, setPermission] = useState({});
    const [AllCategory, setAllCategory] = useState({});

    const [activeSubTab, setActiveSubTab] = useState("");
    const [staticActiveTab, setStaticActiveTab] = useState();

    const [Profile, setProfile] = useState({ account: "", ga: "", circle: "", division: "", subdivision: "", area: "", ins_no: [] });
    const [isDownloadBtnShow, setisDownloadBtnShow] = useState(true);

    const [activeTab, setActiveTab] = useState("");
    const [subActiveTab, setSubActiveTab] = useState("");
    // const [currentCoustomeTab, setCurrentCoustomeTab] = useState('Modem');
    const [DashTabs, setDashTabs] = useState([]);
    const [tabModal, setTabModal] = useState({ isOpen: false, TabID: null, disabled: false, isLoading: false })
    const [tabDetails, setTabDetails] = useState({ name: '', description: '' })


    const onHandleRoles = async () => {
        const data = await getRolesById(userInfo?.role)
        if (data.success === true) {
            const obj = data.data.data[0]
            setPermission({
                ...obj,
                roles_permission_category0: JSON.parse(
                    obj.roles_permission_category0 || '{}'
                ),
                roles_permission_category5: JSON.parse(
                    obj.roles_permission_category5 || '{}'
                ),
                roles_permission_category2: JSON.parse(
                    obj.roles_permission_category2 || '{}'
                ),
                roles_permission_category3: JSON.parse(
                    obj.roles_permission_category3 || '{}'
                ),
                roles_permission_category1: JSON.parse(
                    obj.roles_permission_category1 || '{}'
                ),
                roles_permission_category4: JSON.parse(
                    obj.roles_permission_category4 || '{}'
                ),
                roles_other_permission: JSON.parse(
                    obj.roles_other_permission || '{}'
                ),
            })
        }
    }

    const onGetAllCategoryTableData = async (info) => {
        const data = await getAllCategoryTableData()
        if (data.success === true) {
            setAllCategory(data.data.data)
        } else {
            console.log('something went wrong')
        }
    }

    const getCosutomDashboardListMutation = useMutation({
        mutationFn: getCustomDashboadList,
        onSuccess: (res, variables) => {
            const dashtab = res?.data?.data?.filter((item) => (permission?.roles_other_permission?.GSM_CUSTOM_DASHBOARD?.dashboard_view?.includes(item?.unique_id) || userInfo?.role == 'ROLE1650614500708'))?.map((item) => ({ title: item?.name, value: item?.unique_id, id: item?.unique_id, ...item }))
            setDashTabs(dashtab || [])
            if(permission?.roles_other_permission?.GSM_DASHBOARD?.includes('Custom_Dashboard')){
                setActiveTab('Custom Dashboard')
                setSubActiveTab(dashtab[0]?.value)
            }
        },
        onError: (error) => {
            console.log(error)
        }
    })

    const insertCustomDashboardMutation = useMutation({
        mutationFn: InsertGsmCustomDashboard,
        onSuccess: (res) => {
            toast.success("Add Dashboard Success");
            getCosutomDashboardListMutation.mutate()
            onCloseModal();
        },
        onError: (error) => {
            toast.error(`something want wrong ${JSON.stringify(error)}`);
            setTabModal({ ...tabModal, isLoading: false })
        }
    })

    const updateCustomDashboardMutation = useMutation({
        mutationFn: UpdateGsmCustomDashboard,
        onSuccess: (res) => {
            toast.success("Update Dashboard Success");
            getCosutomDashboardListMutation.mutate()
            onCloseModal();
        },
        onError: (error) => {
            toast.error(`something want wrong ${JSON.stringify(error)}`);
            setTabModal({ ...tabModal, isLoading: false })
        }
    })

    useEffect(() => {
        setProfile({ account: "", ga: "", circle: "", division: "", subdivision: "", area: "", ins_no: [] })
        setisDownloadBtnShow(true)
    }, [activeTab, subActiveTab])

    useEffect(() => {
        (async () => {
            setTabLoader(true);
            await onHandleRoles();
            await onGetAllCategoryTableData();
            setTabLoader(false);
        })()
    }, []);

    useEffect(() => {
        if (activeTab !== 'Static Dashboard' && Object.keys(permission)?.length) {
            getCosutomDashboardListMutation.mutate()
        }
    }, [permission]);

    const onClickOfTab = (tab, subTab) => {
        setActiveTab(tab)
        if (subTab === subActiveTab) {
            setSubActiveTab(null)
        } else {
            setSubActiveTab(subTab)
        }
    }

    const handleTabModel = (TabID = null) => {
        if (TabID) {
            const tabDetails = DashTabs.filter((item) => (item?.id == TabID))[0]
            setTabDetails({ name: tabDetails?.title, description: tabDetails?.description })
        }
        setTabModal({ ...tabModal, isOpen: true, disabled: !!TabID, TabID: TabID, })
    }

    const onSubmit = () => {
        if (tabDetails?.name === " " || !(!!tabDetails?.name)) {
            toast.error("Enter Dashboard Name Is Require");
            setTabModal({ ...tabModal, isLoading: false })
            return;
        }

        if (tabModal?.TabID) {
            updateCustomDashboardMutation.mutate({ ...tabDetails, TabID: tabModal?.TabID })
        } else {
            insertCustomDashboardMutation.mutate({ ...tabDetails })
        }
    };

    const onCloseModal = () => {
        setTabModal({ ...tabModal, isOpen: false, TabID: null, disabled: false })
        setTabDetails({ name: '', description: '' })
    }

    const onChnageFavouriteDashboard = () => {
        const isUserAlreadyHave = DashTabs?.filter()
    }

    if (tabLoader) {
        return (
            <div className="main-content" style={{ minHeight: "562px" }}>
                <Card className='tw-h-[85dvh] tw-flex tw-items-center tw-justify-center' >
                    <GoogleLoader />
                </Card>
            </div>
        )
    }

    return (

        <>

            <CustomTabModal
                TabID={tabModal?.TabID}
                onFiledEditHandler={() => setTabModal({ ...tabModal, disabled: false })}
                isOpen={tabModal?.isOpen}
                handleModelClose={onCloseModal}
                TabDetails={tabDetails}
                setTabDetails={setTabDetails}
                disabled={tabModal?.disabled || insertCustomDashboardMutation?.isPending || updateCustomDashboardMutation?.isPending}
                isLoading={insertCustomDashboardMutation?.isPending || updateCustomDashboardMutation?.isPending}
                onSubmit={onSubmit}
            />

            <div className="main-content" style={{ minHeight: "562px" }}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/all-deshboard">
                                <i className="fas fa-tachometer-alt"></i> Home
                            </Link>
                        </li>
                        <li className="breadcrumb-item">
                            Dashboard
                        </li>
                    </ol>
                </nav>

                <section className="section">
                    <div className="section-body">
                        <Card className='tw-p-2 tw-flex tw-gap-2' >
                            {(permission?.roles_other_permission?.GSM_DASHBOARD?.includes('Custom_Dashboard') || userInfo?.role === "ROLE1650614500708" ) &&
                                <DropdownSelecter
                                    name={'Custom Dashboard'}
                                    value={'Custom Dashboard'}
                                    activeTab={activeTab}
                                    selectedCard={DashTabs?.find(v => v?.value === subActiveTab)?.title || null}
                                    // parentDashBoard={staticTabsDataArrtay?.find(v => v?.value === currentCoustomeTab)?.title || null}
                                    popoverContent={
                                        <TabsPopover
                                            isEdit={true}
                                            // selectorArray={staticTabsDataArrtay}
                                            handleTabModel={handleTabModel}
                                            dataArray={DashTabs}
                                            selectedCard={subActiveTab}
                                            // currentCoustomeTab={currentCoustomeTab}
                                            // setCurrentCoustomeTab={setCurrentCoustomeTab}
                                            setSelectedCard={(value) => onClickOfTab('Custom Dashboard', value)}
                                            isEditPermission={userInfo?.role === "ROLE1650614500708"}
                                            isAddPermission={userInfo?.role === "ROLE1650614500708"}
                                            isLoading={getCosutomDashboardListMutation?.isPending}
                                        />}
                                />
                            }
                            {(permission?.roles_other_permission?.GSM_DASHBOARD?.includes('Static_Dashboard') || userInfo?.role === "ROLE1650614500708" ) &&
                                <DropdownSelecter
                                    name={'Static Dashboard'}
                                    value={'Static Dashboard'}
                                    activeTab={activeTab}
                                    selectedCard={staticTabsDataArrtay?.find(v => v?.value === subActiveTab)?.title || null}
                                    popoverContent={<TabsPopover
                                        dataArray={staticTabsDataArrtay}
                                        selectedCard={subActiveTab}
                                        setSelectedCard={(value) => onClickOfTab('Static Dashboard', value)}
                                    />}
                                />
                            }
                            {Object.keys(permission).length && !permission?.roles_other_permission?.GSM_DASHBOARD?.includes('Custom_Dashboard') && !permission?.roles_other_permission?.GSM_DASHBOARD?.includes('Static_Dashboard') && userInfo?.role !== "ROLE1650614500708" ? <h4>Don't have permission any dashboard</h4> : ''} 
                        </Card>

                        <div className='tw-mt-5' >
                            <div className='tw-grid tw-gap-x-6 tw-gap-y-4 tw-grid-cols-12' >

                                {(subActiveTab === 'Modem' || activeTab === 'Custom Dashboard') && <ModemDashboard permision={permission} activeTab={activeTab} setSubActiveTab={setSubActiveTab} DashTabDetailById={DashTabs?.find(v => v?.value === subActiveTab) || {}} subActiveTab={subActiveTab} DashTabs={DashTabs} setDashTabs={setDashTabs} />}

                                {(subActiveTab === 'Environmental_parameter' || activeTab === 'Custom Dashboard') && <EnvironmentDashboard permision={permission} activeTab={activeTab} setSubActiveTab={setSubActiveTab} DashTabDetailById={DashTabs?.find(v => v?.value === subActiveTab) || {}} subActiveTab={subActiveTab} DashTabs={DashTabs} setDashTabs={setDashTabs} />}

                            </div>
                        </div>

                        {subActiveTab === 'Electrical_parameter' && <IOTDashboard />}
                    </div>
                </section>
            </div>

        </>

    )
}
