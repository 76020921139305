import React, { useEffect, useRef, useState } from 'react'
import { getCustomDashboadList, getGSMOfflineDurationWiseCount, getmodemConnectivityTotalGSM, getPowerSourceTotalGSM, onGetConsumerIncolumeName, updateDashboardAddCustomCard } from '../../Common/Utils';
import { Card, CardContent, CardFooter, CardHeader, CustomeCardHeader } from '../../Common/ui/card';
import ReactApexChart from "react-apexcharts";
import loaderGif from '../../assets/img/loading_chart.gif'
import { Modal, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { getPieChartOptions, coustomeDashboard as card_json, sum } from './utils';
import { DraggableHelper } from '../../Common/ui/DraggableHelper';
import MoreOptionButton from './components/MoreOptionButton';
import { CaptureImage } from '../../Common/Download/ChartDownload';
import { Popover, PopoverContent, PopoverTrigger } from '../../Common/ui/popover';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { Button } from '../../Common/ui/button';
import { Loader } from 'lucide-react';
import swal from 'sweetalert2';
import { Chip } from '../../Common/ui/chip';
import { BootstrapTooltip } from '../../Common/common';
import moment from 'moment';


export default function ModemDashboard(props) {
  const card1Ref = useRef(); 
  const Modem_Connectivity_Status_Chart = useRef();
  const { permision, setSubActiveTab, activeTab, DashTabDetailById = {}, subActiveTab, onChnageCardDetsiles, DashTabs, setDashTabs } = props; 
  const view_json = JSON.parse(DashTabDetailById?.view_json || '{}') 
  const [PowerSource, setPowerSource] = useState({ series: [], category: [], code: [], isLoading: true, isError: false });

  const [TableData, setTableDataInApi] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ModelId, setModelId] = useState('')
  const [ModalLoad, setModalLoad] = useState(false)
  const [Profile, setProfile] = useState({ account: "", ga: "", circle: "", division: "", subdivision: "", area: "", ins_no: [] });
  const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
  let colors = ['#008ffb', '#00e396', '#feb019', '#ff4560', '#775dcf', '#FF7700', '#554994', "#D36B00", "#0096FF"];

  const [isDashModal, setisDashModal] = useState(false); 
  const [Modal_card_name, setModal_card_name] = useState('') 
  const [card_id, setcard_id] = useState('') 
  const [CardFilter, setCardFilter] = useState({}) 
  const [CardBar, setCardBar] = useState([]) 
  const [DEVICE_STATUS,setDEVICE_STATUS]=useState({ series: [], categories: [] })
  const [OfflineDuration,setOfflineDuration]=useState({ data: {} })

  const ongetPowerSourceTotalGSM = async (info) => {
    setPowerSource((prv) => ({ ...prv, series: [], category: [], isLoading: true, isError: false }))
    const data = await getPowerSourceTotalGSM(info);
    if (data.success === true) {
      const chartData = data?.data?.data[0];
      const obj = {
        series: [chartData?.external_count || 0, chartData?.battery_count || 0],
        // chartData?.battery_disable_count || 0, chartData?.null_count || 0
        category: [`External Power (${chartData?.external_count || 0})`, `Battery (${chartData?.battery_count || 0})`],
        // , `Without Battery (${chartData?.battery_disable_count || 0})`, `null (${chartData?.null_count || 0})`
      };
      setPowerSource((prv) => ({ ...prv, ...obj, isLoading: false, isError: false }))
    } else {
      setPowerSource((prv) => ({ ...prv, series: [], category: [], isLoading: false, isError: true }))
      console.log("something went warong");
    }
  }

  const ongetmodemConnectivityTotalGSM = async (info) => {
    setDEVICE_STATUS({ series: [], categories: [],isLoading: true, isError: false })
    const data = await getmodemConnectivityTotalGSM(info);
    if (data.success === true) {
      const obj = { series: data.data.data.map((item) => (item?.total)), categories: data.data.data.map((item) => (item?.DEVICE_STATUS + '(' + item?.total + ')')) }
      setDEVICE_STATUS({...obj,isLoading: false, isError: false });
    } else {
      setDEVICE_STATUS({...DEVICE_STATUS,isLoading: false, isError: true });
      console.log("something went warong");
    }
  }

  const ongetGSMOfflineDurationWiseCount = async (info) => {
    setOfflineDuration({ series: [], categories: [],isLoading: true, isError: false })
    const data = await getGSMOfflineDurationWiseCount(info);
    if (data.success === true) {
      const obj_data=data.data.data||{}
      setOfflineDuration({data:obj_data,isLoading: false, isError: false });
    } else {
      setOfflineDuration({...OfflineDuration,isLoading: false, isError: true });
      console.log("something went warong");
    }
  }

  //  // T-TD14729 add
  //   const getCosutomDashboardListMutation = useMutation({
  //     mutationFn: getCustomDashboadList,
  //     onSuccess: (res) => {
  //       const dashtab = res?.data?.data?.filter((item) => (permision?.ROLES_OTHER_PERMISSIONS?.CUSTOM_DASHBOARD?.dashboard_view?.includes(item?.unique_id) || userInfo?.role == 'ROLE1650614500708') || true)?.map((item) => ({ title: item?.name, value: item?.unique_id, id: item?.unique_id, ...item }))
  //       setDashTabs(dashtab || []);
  //     },
  //     onError: (error) => {
  //       console.log(error)
  //     }
  //   })
  //  // T-TD14729 add
  //   useEffect(() => {
  //     getCosutomDashboardListMutation.mutate()
  //   },[])
  // T-TD14729 add
  useEffect(() => {
    if (permision) {
      const infoRolesObj = { ROLES: userInfo?.role, Profile: { CATEGORY0: permision?.roles_permission_category0?.CATEGORY0_view, CATEGORY1: permision?.roles_permission_category1?.CATEGORY1_view, CATEGORY2: permision?.roles_permission_category2?.CATEGORY2_view, CATEGORY3: permision?.roles_permission_category3?.CATEGORY3_view, CATEGORY4: permision?.roles_permission_category4?.CATEGORY4_view, CATEGORY5: permision?.roles_permission_category5?.CATEGORY5_view } }

      if (view_json[card_json[0].U_ID]?.display == true || activeTab !== 'Custom Dashboard') {
        ongetPowerSourceTotalGSM(infoRolesObj)
      }
      if (view_json[card_json[3].U_ID]?.display == true || activeTab !== 'Custom Dashboard') {
        ongetmodemConnectivityTotalGSM(infoRolesObj)
      }
      if (view_json[card_json[4].U_ID]?.display == true || activeTab !== 'Custom Dashboard') {
        ongetGSMOfflineDurationWiseCount(infoRolesObj)
      }
    }
  }, [permision, subActiveTab])

  const GetConsumerIncolumeName = async (isbetween, col, colname, value, CARD_ID) => {
    setIsModalVisible(true)
    setModalLoad(true)
    setModelId(`${colname}`)
    let obj = {}

    obj = { isbetween: isbetween, colName: col, value: value, CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ins_no: Profile.ins_no, ROLES: userInfo?.role, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' && Profile?.ins_no?.length == 0 ? false : true, Profile: { CATEGORY0: permision?.roles_permission_category0?.CATEGORY0_view, CATEGORY1: permision?.roles_permission_category1?.CATEGORY1_view, CATEGORY2: permision?.roles_permission_category2?.CATEGORY2_view, CATEGORY3: permision?.roles_permission_category3?.CATEGORY3_view, CATEGORY4: permision?.roles_permission_category4?.CATEGORY4_view, CATEGORY5: permision?.roles_permission_category5?.CATEGORY5_view } }

    const data = await onGetConsumerIncolumeName(obj)
    if (data.success === true) {
      let tempData = data?.data?.data.map((item) => ({ ...item, MMD_STATUS_PWR_SOURCE: item?.HEARTBEAT_LAST_DATA ? JSON.parse(item?.HEARTBEAT_LAST_DATA || '{}').MMD_STATUS_PWR_SOURCE : null, ALERT_MMD_STATUS_PWR_SOURCE: item?.ALERT_DISABLE ? JSON.parse(item?.ALERT_DISABLE || '{}').MMD_STATUS_PWR_SOURCE : null }));
      setTableDataInApi(tempData)
      setModalLoad(false)
    } else {
      console.log('somthing want wrong')
      setModalLoad(false)

    }
  }

  var options1 = getPieChartOptions(
    350,
    PowerSource.category,
    colors,
    function (event, chartContext, config) {
      var date_index = config.dataPointIndex;
      if (date_index == 0) {
        GetConsumerIncolumeName(false, '', PowerSource.category[date_index].split('(')[0], `(JSON_VALUE(heartbeat_last_data,'$.mmd_status_pwr_source')=1  AND (JSON_VALUE(alert_disable,'$.mmd_status_pwr_source') IS NULL OR  JSON_VALUE(alert_disable,'$.mmd_status_pwr_source')!=0))`)
      } else if (date_index == 1) {
        GetConsumerIncolumeName(false, '', PowerSource.category[date_index].split('(')[0], `((JSON_VALUE(alert_disable,'$.mmd_status_pwr_source')=1 OR JSON_VALUE(alert_disable,'$.mmd_status_pwr_source') IS NULL ) AND JSON_VALUE(heartbeat_last_data,'$.mmd_status_pwr_source')=0 )`)
      } else if (date_index == 2) {
        GetConsumerIncolumeName(false, '', PowerSource.category[date_index].split('(')[0],
          `JSON_VALUE(alert_disable,'$.mmd_status_pwr_source')=0`);
      } else {
        GetConsumerIncolumeName(false, '', PowerSource.category[date_index].split('(')[0],
          `JSON_VALUE(heartbeat_last_data,'$.mmd_status_pwr_source') IS null`);
      }
    },
    function (val, opt) {
      return PowerSource.series[opt.seriesIndex];
    }
  );

  const options10 = getPieChartOptions(
    350,
    DEVICE_STATUS.categories,
    [DEVICE_STATUS?.categories[0]?.split('(')[0] === 'online' ? '#54ca68' : '#fc544b', DEVICE_STATUS?.categories[1]?.split('(')[0] === 'offline' ? '#fc544b' : '#54ca68'],
    function (event, chartContext, config) {
      var date_index = config.dataPointIndex;
      GetConsumerIncolumeName(false, '', DEVICE_STATUS.categories[date_index].split('(')[0], `mmd_status_online_offline='${DEVICE_STATUS.categories[date_index].split('(')[0]}'`, card_json[1].U_ID)
    },
    function (val, opt) {
      return DEVICE_STATUS.series[opt.seriesIndex];
    }
  );

  const handleCancel = () => {
    setIsModalVisible(false);
    setTableDataInApi([])
  };

  const onHandaleViewGSM = (id) => {
    window.open(`/view_existing_consumer_gsm/${id}`, '_blank')
  }
  // T-TD14729 add
  const onHandaleChange = (e, data, id) => {
    if (e.target.checked) {
      var dash_info = DashTabs;
      var obj = JSON.parse(data?.view_json || '{}')
      obj[card_id] = { ...obj[card_id] || {}, position: Object.keys(obj).length + 1 || 1, display: true, bar_pemission: [...CardBar.map(item => item?.name)] };
      dash_info[id].view_json = JSON.stringify(obj)
      setDashTabs([...dash_info])
    } else {
      var dash_info = DashTabs
      var obj = JSON.parse(data?.view_json || '{}')
      delete obj[card_id];
      dash_info[id].view_json = JSON.stringify(obj)
      setDashTabs([...dash_info])
    }
  }
  // T-TD14729 add
  const onHandaleBarChange = (e, data, id) => {
    if (e.target.checked) {
      var dash_info = DashTabs;
      var obj = JSON.parse(data?.view_json || '{}')
      obj[card_id] = { ...obj[card_id], bar_pemission: [...obj[card_id]?.bar_pemission || [], e.target.name] };
      dash_info[id].view_json = JSON.stringify(obj)
      setDashTabs([...dash_info])
    } else {
      var dash_info = DashTabs
      var obj = JSON.parse(data?.view_json || '{}')
      obj[card_id] = { ...obj[card_id], bar_pemission: obj[card_id]?.bar_pemission?.filter((item) => (item !== e.target.name)) }
      dash_info[id].view_json = JSON.stringify(obj)
      setDashTabs([...dash_info])
    }
  }
  // T-TD14729 add
  const UpdateCustomDasboardMutation = useMutation({
    mutationFn: updateDashboardAddCustomCard,
    onSuccess: (res, variables) => {
      if (variables?.isremove) {
        // console.log(variables?.DashTabs, DashTabs)
        toast.success('Remove card in Dashboard successfully')
        // setDashTabs(variables?.DashTabs);
        setDashTabs((prevTabs) =>
          prevTabs.map((tab) =>
            tab?.value === subActiveTab
              ?  variables?.DashTabs[0]
              : tab
          )
        );
      } else if (variables?.isPostionChange) {
        toast.success('Position of card change in this Dashboard successfully')
        setDashTabs(variables?.DashTabs);
      } else {
        toast.success('Added card in Dashboard successfully')
        setisDashModal(false)
        setcard_id('')
        setCardBar([])
      }
    },
    onError: (error) => {
      toast.error('Server Issue')
    }
  })
  // T-TD14729 add
  const UpdateCustomDasboardInCardDelete = async (card_id) => {
    swal.fire({
      title: "Are you sure?",
      text: "card remove in this dashboard",
      icon: "warning",
      showCancelButton: true,
    }).then(async (willDelete) => {
      if (willDelete.isConfirmed) {
        let data_info = DashTabDetailById
        var viewJson = JSON.parse(data_info?.view_json || '{}');
        if (viewJson[card_id]) {
          delete viewJson[card_id];
          data_info.view_json = JSON.stringify(viewJson);
        }
        UpdateCustomDasboardMutation.mutate({ DashTabs: [data_info], isremove: true })
      }
    })
  }
  // T-TD14729 add
  const UpdateCustomDasboardInCardPosition = async (viewJson, draggedCard) => {
    swal.fire({
      title: "Are you sure?",
      text: `Are you sure the position of the ${card_json.filter((item) => item?.U_ID == draggedCard)[0].CARD_NAME} card has changed in this dashboard?`,
      icon: "warning",
      showCancelButton: true,
    }).then(async (willDelete) => {
      if (willDelete.isConfirmed) {
        var updatedDashTabs = { ...DashTabDetailById };
        updatedDashTabs.view_json = JSON.stringify(viewJson)
        UpdateCustomDasboardMutation.mutate({ DashTabs: [updatedDashTabs], isPostionChange: true })
      }
    })
  }
  // T-TD14729 add
  const onDrop = (draggedId, targetId, draggedCard, targetCard) => {
    // Clone the DashTabs object to avoid direct state mutation
    const updatedDashTabs = { ...DashTabDetailById };
    // Parse the view_json
    // let viewJson ={}
    const viewJson = Object.entries(JSON.parse(updatedDashTabs?.view_json || "{}")).reduce((acc, [key, value], index) => {
      acc[key] = { ...value, position: value?.position || index + 1 };
      return acc;
    }, {});


    // Check for invalid cards
    if (!draggedCard || !targetCard || !viewJson[draggedCard] || !viewJson[targetCard]) {
      console.error("Invalid card IDs. Exiting function.");
      return;
    }

    // Sort the keys of viewJson based on the 'position' property
    const sortedKeys = Object.keys(viewJson).sort((a, b) => viewJson[a].position - viewJson[b].position);
    // Update the position of the dragged card
    const oldPosition = viewJson[draggedCard].position;
    const newPosition = parseInt(targetId);
    viewJson[draggedCard].position = newPosition;

    // Determine the direction of the drag
    const dragDirection = newPosition < oldPosition ? 1 : -1;
    const start = Math.min(oldPosition, newPosition);
    const end = Math.max(oldPosition, newPosition);

    // Update positions of cards between start and end
    sortedKeys.forEach((currentCard, index) => {
      if (currentCard === draggedCard) return;
      const currentPosition = viewJson[currentCard].position;
      if (currentPosition >= start && currentPosition <= end) {
        viewJson[currentCard].position = (viewJson[currentCard].position) + dragDirection;
      }
    });
    
    UpdateCustomDasboardInCardPosition(viewJson, draggedCard);
  };

  const CalculationInBatterypercentage = (min_v,max_v,modem_voltage) => {
    if(min_v==null||max_v==null||modem_voltage==null) return '-' 
    if(min_v>modem_voltage) return 0
    if(max_v<modem_voltage) return 100
    return ((modem_voltage-min_v)/(max_v-min_v))*100
  }

  const seconTominutesConveter = (seconds) => {
		// var duration = moment.duration(seconds, 'seconds');
		const minutes = Math.floor(seconds / 60);
		const secondsi = seconds - minutes * 60;
		return `${minutes.toFixed(0).toString().padStart(2, '0')}:${secondsi.toFixed(0).toString().padStart(2, '0')}`
	}

  function getSecondsTodayBydate(ts) {
    var now = moment().add(-moment().utcOffset(), "m").format("DD-MM-YYYY HH:mm:ss");
    var then = moment.utc(ts).add(-moment().utcOffset(), "m").format("DD-MM-YYYY HH:mm:ss");
    return moment(now, "DD-MM-YYYY HH:mm:ss").diff(moment(then, "DD-MM-YYYY HH:mm:ss"), 'second')
  }
  return (
    <>
      <Modal title={<div className="d-flex"><h5 >{ModelId}</h5>{TableData.length > 0 ? <span className='btn btn-primary text-white ml-2' ><i className='fas fa-download'></i></span> : ''}{TableData.length > 0 ? <Link to={{ pathname: `All-GSM`, state: { detail: TableData.map((data) => (data?.UNIQUE_ID)), title: ModelId } }}><span className='btn btn-primary text-white ml-2'><i className='fas fa-up-right-from-square'></i></span></Link> : ''}</div>} maskClosable={false} className="my-modal-className" visible={isModalVisible} footer='' onCancel={handleCancel} width={1000} >
        <div>
          <div className="modal-table">
            {ModalLoad ? (
              <div className="example">
                <Spin size="large" />
              </div>
            ) : <table className="table border text-center table-borderless" id="table-1">
              <thead style={{ position: 'sticky', top: '0', background: '#fff' }}>
                <tr>
                  <th className="text-center">SR NO.</th>
                  <th >Company/Client Name</th>
                  <th>Modem Serial No</th>
                  <th>Modem Connectivity Status</th>
                  <th>Heartbeat Last Seen</th>
                  {ModelId=='Battery '?<><th>Battery Percentage(%)</th>
                  <th>Approx Due Time (Minute)</th>
                  <th>Battery Maximum Approx Time (Minute)</th></>:''}
                </tr>
              </thead>
              <tbody id="tbody">
                <>
                  {TableData.length > 0 ?
                    TableData.map((data, id) => (
                      <tr style={{ height: "4rem", cursor: 'pointer' }} className="trheigth even:tw-bg-gray-100 hover:tw-bg-[#D8D8D8]" key={id} onClick={() => onHandaleViewGSM(data?.unique_id)}>
                        <td className="text-center">{id + 1}</td>
                        <td>{data?.company_name}</td>
                        <td>{data?.modem_serial_no}</td>
                        <td>{!data?.mmd_status_online_offline?'-':data?.mmd_status_online_offline=='online'?<Chip className='tw-gap-1 !tw-rounded-[4px]' variant='light' color='green' radius='md' size='sm' border='none'>{data?.mmd_status_online_offline}</Chip>:<Chip className='tw-gap-1 !tw-rounded-[4px]' variant='light' color='red' radius='md' size='sm' border='none'>{data?.mmd_status_online_offline}</Chip>}</td>
                        {/* <td>{data?.scr_ts_online_offline}</td> */}
                        <td className="text-center">{data.heartbeat_ts?getSecondsTodayBydate(data.heartbeat_ts) < (data.heartbeat_interval) ? <div className="badge badge-success badge-shadow text-white">{data?.heartbeat_ts ? moment.utc(data.heartbeat_ts).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : ''}</div> : <div className="badge badge-danger badge-shadow text-white">{data?.heartbeat_ts ? moment.utc(data.heartbeat_ts).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : ''}</div>:'-'}</td>
                        {ModelId=='Battery '?<><td>{CalculationInBatterypercentage(data?.min_modem_batt_v,data?.max_modem_batt_v,JSON.parse(data?.heartbeat_last_data||'{}')?.mmd_info_batt_v)!='-'?CalculationInBatterypercentage(data?.min_modem_batt_v,data?.max_modem_batt_v,JSON.parse(data?.heartbeat_last_data||'{}')?.mmd_info_batt_v).toFixed(2):'-'}</td>
                        <td>{CalculationInBatterypercentage(data?.min_modem_batt_v,data?.max_modem_batt_v,JSON.parse(data?.heartbeat_last_data||'{}')?.mmd_info_batt_v)!='-'?CalculationInBatterypercentage(data?.min_modem_batt_v,data?.max_modem_batt_v,JSON.parse(data?.heartbeat_last_data||'{}')?.mmd_info_batt_v)>0?seconTominutesConveter((CalculationInBatterypercentage(data?.min_modem_batt_v,data?.max_modem_batt_v,JSON.parse(data?.heartbeat_last_data||'{}')?.mmd_info_batt_v)*data?.max_device_up_time_sec)/100):0:''}</td>
                        <td>{data?.max_device_up_time_sec?seconTominutesConveter(data?.max_device_up_time_sec):'-'}</td></>:''}
                      </tr>
                    )) : <tr></tr>}
                </>
              </tbody>
            </table>}
            {TableData.length == 0 && !ModalLoad && <h4 className="text-center">No Data Found</h4>}

          </div>
        </div>
      </Modal>
      {/* // T-TD14729 add */}
      <Modal
        title={"Custom Dashboard permission add " + `(${Modal_card_name})`}
        visible={isDashModal}
        footer=''
        onCancel={() => {
          setisDashModal(false)
          setcard_id('')
          setCardBar([])
        }}
        width={1000}
        height={300}
        style={{
          borderRadius: '12px',
          // overflow: 'hidden',
          padding: "0px"
        }}
      >
        <div className="modal-table">
          <table className="table border table-borderless text-center" id="table-1">
            <thead style={{ position: 'sticky', top: '0', background: '#fff' }}>
              <tr>
                <th className="text-center">Dashboard Name</th>
                <th className="text-center">Add Card</th>
                <th className="text-center">Card Other Permission</th>
              </tr>
            </thead>
            <tbody id="tbody">
              <>
                {DashTabs.length > 0 ?
                  DashTabs.map((data, id) => (
                    <tr key={data?.value} >
                      <td>{data?.name}</td>
                      <td><input type="checkbox" className="chk_MSSQL1" name="checkAll" onChange={(e) => { onHandaleChange(e, data, id) }} checked={JSON.parse(data?.view_json || "{}")[card_id]?.display || false} /></td>

                      <Popover>
                        <PopoverTrigger>
                          <th className='tw-cursor-pointer tw-text-blue-600'>More permission</th>
                        </PopoverTrigger>
                        <PopoverContent showArrow={true} className='tw-z-[1030] tw-w-auto' >
                          <table className="table table-borderless border">
                            <thead className='border leading-8' style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
                              <tr >
                                <th scope="col" className="thalignleft tw-font-normal text-start" style={{ width: "40%" }}>Lable</th>
                                <th scope="col" className="thalign tw-font-normal text-start" style={{ width: "20%" }}>View</th>
                              </tr>
                            </thead>
                            <tbody className='leading-8 ' >
                              {CardBar?.map((perm, index) => (
                                <tr key={index} >
                                  <th scope='row' className='text-start tw-font-normal' style={{ height: "40px" }}>
                                    {perm?.name}
                                  </th>
                                  <th className=' text-start font-normal' style={{ height: "40px" }}>
                                    <input className='' type={"checkbox"} name={perm?.name} id={perm?.name}
                                      onChange={(e) => (onHandaleBarChange(e, data, id))} checked={JSON.parse(data?.view_json || "{}")[card_id]?.bar_pemission?.includes(perm?.name)}></input>
                                  </th>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </PopoverContent>
                      </Popover>
                    </tr>
                  )) : <tr></tr>}
              </>
            </tbody>
          </table>
          <div className="">
            <Button variant='indigo' type='button' disabled={UpdateCustomDasboardMutation?.isPending} className='!tw-text-xs !tw-font-bold mr-2 tw-bg-red-500 hover:tw-bg-red-600'
              onClick={() => UpdateCustomDasboardMutation.mutate({ DashTabs })}>
              {UpdateCustomDasboardMutation?.isPending ? <Loader size={14} className='tw-animate-spin !tw-text-base' /> : 'Submit'}
            </Button>
          </div>
        </div>
      </Modal>

      {/* <div className='tw-mt-5' >
        <div className='tw-grid tw-gap-x-6 tw-gap-y-4 tw-grid-cols-12' > */}

      {/* // T-TD14729 update  */}
      {(view_json?.[card_json[0].U_ID]?.display === true || activeTab !== 'Custom Dashboard') &&
        (
          <DraggableHelper isOrderActive={activeTab == 'Custom Dashboard'} onDrop={onDrop} card={card_json[0].U_ID} id={view_json[card_json[0].U_ID]?.position || 1} className={`md:tw-col-span-6 tw-col-span-12`}>
            <Card className=' border-0 border-top !tw-border-t-2 !tw-border-t-binfo ' >
              <CustomeCardHeader
                IsDrag={activeTab === 'Custom Dashboard'}
                title={card_json[0].CARD_NAME}
                className="tw-flex tw-justify-between tw-items-start tw-flex-row tw-border-b"
                tooltip={`Find Data In globle_gsm_modem_list (MMD_STATUS_PWR_SOURCE) If HEARTBEAT_LAST_DATA.MMD_STATUS_PWR_SOURCE = 1 OR ALERT_DISABLE.MMD_STATUS_PWR_SOURCE IS NUll OR  ALERT_DISABLE.MMD_STATUS_PWR_SOURCE = 0 Then External Power If ALERT_DISABLE.MMD_STATUS_PWR_SOURCE = 1 AND HEARTBEAT_LAST_DATA.MMD_STATUS_PWR_SOURCE = 0 Then Battery Power If ALERT_DISABLE.MMD_STATUS_PWR_SOURCE = 0 Then Batter Backup Not Available`}
              >
                <MoreOptionButton
                  captureImage={(e) => CaptureImage(card1Ref, e, card_json[0].CARD_NAME)}
                  activeTab={activeTab}
                  hasAddCardPermission={permision?.roles_other_permission?.GSM_CUSTOM_DASHBOARD?.card_add_in_dashboard === true ||
                    userInfo?.role === 'ROLE1650614500708'}
                  onAddCard={() => {
                    setisDashModal(true);
                    setModal_card_name(card_json[0].CARD_NAME);
                    setCardBar([]);
                    setcard_id(card_json[0].U_ID);
                  }}
                  onRemoveCard={() => UpdateCustomDasboardInCardDelete(card_json[0].U_ID)}
                />

              </CustomeCardHeader>
              <CardContent>
                <div
                  ref={card1Ref}
                  className="card-body"
                  style={{ position: "relative", paddingLeft: "0px", paddingRight: "0px", height: "395px" }}>
                  {PowerSource?.isLoading ? <div className="d-flex tw-items-center tw-justify-center tw-h-full" ><img className="loader_center text-center" src={loaderGif} /></div> :
                    PowerSource?.isError ? <div className="d-flex tw-font-bold tw-text-sm tw-items-center tw-justify-center tw-h-full " >Somthing Went wrong</div> :
                      PowerSource?.series?.length > 0 && PowerSource?.category?.length > 0 ? <ReactApexChart options={options1} type="pie" series={PowerSource?.series} height={options1.chart.height} /> : <div className="d-flex tw-font-bold tw-text-sm tw-items-center tw-justify-center tw-h-full" >Data Not Available</div>}
                </div>

                <CardFooter className='tw-flex tw-justify-between tw-px-3 !tw-pb-0' >
                  <p className='!tw-m-0'>Total Count :  {sum(PowerSource?.series)}</p>
                  <p className='!tw-m-0'>{'Pie chart(Dynamic)'} </p>
                </CardFooter>
              </CardContent>
            </Card>
          </DraggableHelper>
        )
      }
      {(view_json?.[card_json[3].U_ID]?.display === true || activeTab !== 'Custom Dashboard') &&
        (
          <DraggableHelper isOrderActive={activeTab == 'Custom Dashboard'} onDrop={onDrop} card={card_json[3].U_ID} id={view_json[card_json[3].U_ID]?.position || 1} className={`md:tw-col-span-6 tw-col-span-12`}>
            <Card className=' border-0 border-top !tw-border-t-2 !tw-border-t-binfo ' >
              <CustomeCardHeader
                IsDrag={activeTab === 'Custom Dashboard'}
                title={card_json[3].CARD_NAME}
                className="tw-flex tw-justify-between tw-items-start tw-flex-row tw-border-b"
                tooltip={`Find Data In globle_gsm_modem_list (mmd_status_online_offline)`}
              >
                <MoreOptionButton
                  captureImage={(e) => CaptureImage(card1Ref, e, card_json[3].CARD_NAME)}
                  activeTab={activeTab}
                  hasAddCardPermission={permision?.roles_other_permission?.GSM_CUSTOM_DASHBOARD?.card_add_in_dashboard === true ||
                    userInfo?.role === 'ROLE1650614500708'}
                  onAddCard={() => {
                    setisDashModal(true);
                    setModal_card_name(card_json[3].CARD_NAME);
                    setCardBar([]);
                    setcard_id(card_json[3].U_ID);
                  }}
                  onRemoveCard={() => UpdateCustomDasboardInCardDelete(card_json[3].U_ID)}
                />

              </CustomeCardHeader>
              <CardContent>
              <div
                          ref={Modem_Connectivity_Status_Chart}
                          style={{ position: "relative", paddingLeft: "0px", paddingRight: "0px", height: "395px" }}>

                {DEVICE_STATUS?.isLoading ? <div className="d-flex tw-items-center tw-justify-center tw-h-full" ><img className="loader_center text-center" src={loaderGif} /></div> :
                    DEVICE_STATUS?.isError ? <div className="d-flex tw-font-bold tw-text-sm tw-items-center tw-justify-center tw-h-full " >Somthing Went wrong</div> :
                      DEVICE_STATUS?.series?.length > 0 && DEVICE_STATUS?.categories?.length > 0 ? <ReactApexChart options={options10} type="pie" series={DEVICE_STATUS.series} height={options10.chart.height} /> : <div className="d-flex tw-font-bold tw-text-sm tw-items-center tw-justify-center tw-h-full" >Data Not Available</div>}
                         
                        </div>

                <CardFooter className='tw-flex tw-justify-between tw-px-3 !tw-pb-0' >
                  <p className='!tw-m-0'>Total Count :  {sum(DEVICE_STATUS?.series)}</p>
                  <p className='!tw-m-0'>{'Pie chart(Dynamic)'} </p>
                </CardFooter>
              </CardContent>
            </Card>
          </DraggableHelper>
        )
      }

{(view_json?.[card_json[4].U_ID]?.display === true || activeTab !== 'Custom Dashboard') &&
        (
          <DraggableHelper isOrderActive={activeTab == 'Custom Dashboard'} onDrop={onDrop} card={card_json[4].U_ID} id={view_json[card_json[4].U_ID]?.position || 1} className={`md:tw-col-span-6 tw-col-span-12`}>
            <Card className=' border-0 border-top !tw-border-t-2 !tw-border-t-binfo ' >
              <CustomeCardHeader
                IsDrag={activeTab === 'Custom Dashboard'}
                title={card_json[4].CARD_NAME}
                className="tw-flex tw-justify-between tw-items-start tw-flex-row tw-border-b"
                tooltip={`Find Data In globle_gsm_modem_list (mmd_status_online_offline)`}
              >
                <MoreOptionButton
                  captureImage={(e) => CaptureImage(card1Ref, e, card_json[4].CARD_NAME)}
                  activeTab={activeTab}
                  hasAddCardPermission={permision?.roles_other_permission?.GSM_CUSTOM_DASHBOARD?.card_add_in_dashboard === true ||
                    userInfo?.role === 'ROLE1650614500708'}
                  onAddCard={() => {
                    setisDashModal(true);
                    setModal_card_name(card_json[4].CARD_NAME);
                    setCardBar([]);
                    setcard_id(card_json[4].U_ID);
                  }}
                  onRemoveCard={() => UpdateCustomDasboardInCardDelete(card_json[4].U_ID)}
                />

              </CustomeCardHeader>
              <CardContent>
              <table class="table table-borderless table-striped ">
													<tbody>
														<tr style={{ position: 'sticky', top: '0', background: '#fff' }}>
															<th className="text-center ">Duration</th>
															<th className="text-center ">Offline Consumer</th>
														</tr>
														<tr>
															<td style={{ height: "35px" }} className='text-center'>0-3 hours</td>
															<td style={{ height: "35px", cursor: 'pointer' }} className='text-center text-info'  onClick={()=>GetConsumerIncolumeName(false, '', '0-3 hours', `TIMESTAMPDIFF(HOUR, scr_ts_online_offline, NOW()) <= 3 AND mmd_status_online_offline = 'offline'`, card_json[1].U_ID)}>{OfflineDuration?.data?.["0_3_hours"]||0}</td>
														</tr>
														<tr>
															<td style={{ height: "35px" }}  className='text-center'>3-24 hours</td>
															<td style={{ height: "35px", cursor: 'pointer' }} className='text-center text-info' onClick={()=>GetConsumerIncolumeName(false, '', '3-24 hours', `TIMESTAMPDIFF(HOUR, scr_ts_online_offline, NOW()) > 3 AND TIMESTAMPDIFF(HOUR, scr_ts_online_offline, NOW()) <= 24 AND mmd_status_online_offline = 'offline'`, card_json[1].U_ID)}>{OfflineDuration?.data?.["3_24_hours"]||0}</td>
														</tr>
														<tr>
															<td style={{ height: "35px" }}  className='text-center'>1 day</td>
															<td style={{ height: "35px", cursor: 'pointer' }} className='text-center text-info' onClick={()=>GetConsumerIncolumeName(false, '', '1 days', `TIMESTAMPDIFF(DAY, scr_ts_online_offline, NOW()) = 1 AND mmd_status_online_offline = 'offline'`, card_json[1].U_ID)}>{OfflineDuration?.data?.["1_day"]||0}</td>
														</tr>
                            <tr>
															<td style={{ height: "35px" }}  className='text-center'>2 days</td>
															<td style={{ height: "35px", cursor: 'pointer' }} className='text-center text-info' onClick={()=>GetConsumerIncolumeName(false, '', '2 days', `TIMESTAMPDIFF(DAY, scr_ts_online_offline, NOW()) = 2 AND mmd_status_online_offline = 'offline'`, card_json[1].U_ID)}>{OfflineDuration?.data?.["2_days"]||0}</td>
														</tr>
                            <tr>
															<td style={{ height: "35px" }}  className='text-center'>3 days</td>
															<td style={{ height: "35px", cursor: 'pointer' }} className='text-center text-info' onClick={()=>GetConsumerIncolumeName(false, '', '3 days', `TIMESTAMPDIFF(DAY, scr_ts_online_offline, NOW()) = 3 AND mmd_status_online_offline = 'offline'`, card_json[1].U_ID)}>{OfflineDuration?.data?.["3_days"]||0}</td>
														</tr>
                            <tr>
															<td style={{ height: "35px" }}  className='text-center'>4-10 days</td>
															<td style={{ height: "35px", cursor: 'pointer' }} className='text-center text-info'  onClick={()=>GetConsumerIncolumeName(false, '', '4-10 days', `TIMESTAMPDIFF(DAY, scr_ts_online_offline, NOW()) BETWEEN 4 AND 10 AND mmd_status_online_offline = 'offline'`, card_json[1].U_ID)}>{OfflineDuration?.data?.["4_10_days"]||0}</td>
														</tr>
                            <tr>
															<td style={{ height: "35px" }}  className='text-center'>> 10 days</td>
															<td style={{ height: "35px", cursor: 'pointer' }} className='text-center text-info'  onClick={()=>GetConsumerIncolumeName(false, '', '> 10 days', `TIMESTAMPDIFF(DAY, scr_ts_online_offline, NOW()) > 10 AND mmd_status_online_offline = 'offline'`, card_json[1].U_ID)}>{OfflineDuration?.data?.["10_days"]||0}</td>
														</tr>
													</tbody>
												</table>
              </CardContent>
            </Card>
          </DraggableHelper>
        )
      }
      {/* </div>
      </div> */}
    </>
  )
}
