// import React, { useState } from "react";

// export const DraggableHelper = ({ id, children, onDrop, className,card, isOrderActive = false }) => {
//   const [isDragging, setIsDragging] = useState(false); // State to manage dragging state

//   const handleDragStart = (e) => {
//     const dragData = JSON.stringify({ id, card });
//     e.dataTransfer.setData("application/json", dragData);
//     setIsDragging(true);

//   };

//   const handleDragEnd = () => {
//     setIsDragging(false); // Reset dragging state when drag ends
//   };

//   const handleDragOver = (e) => {
//     e.preventDefault();
//   };

//   const handleDrop = (e) => {
//     // e.preventDefault();
//     // const draggedId = e.dataTransfer.getData("text/plain");
//     // e.stopPropagation();
//     // onDrop(draggedId, id);

    

//     e.preventDefault();
//     const draggedData = JSON.parse(e.dataTransfer.getData("application/json"));
//     e.stopPropagation();
//     onDrop(draggedData.id, id, draggedData.card, card);
//     setIsDragging(false); // Reset dragging state when an item is dropped

//   };

//   return (
//     <div
//       className={` ${className ? className : ''} ${!isOrderActive ? '' : 'tw-cursor-grabbing'} ${isDragging ? 'tw-cursor-grabbing' : ''}`} // Dynamically update cursor style
//       style={isOrderActive ?  { order:  parseInt(id) } : {}}
//       draggable={isOrderActive} // Conditionally set the draggable attribute
//       onDragStart={isOrderActive ? handleDragStart : undefined} // Only attach drag events if isOrderActive is true
//       onDragOver={isOrderActive ? handleDragOver : undefined} // Only attach drag events if isOrderActive is true
//       onDrop={isOrderActive ? handleDrop : undefined} // Only attach drag events if isOrderActive is true
//       onDragEnd={isOrderActive ? handleDragEnd : undefined} // Handle drag end to reset cursor
//     >
//       {children}
//     </div>
//   );
// };

import { motion } from "framer-motion";

export const DraggableHelper = ({ id, children, onDrop, className, card, isOrderActive = false }) => {
  const handleDragStart = (e) => {
    const dragData = JSON.stringify({ id, card });
    e.dataTransfer.setData("application/json", dragData);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const draggedData = JSON.parse(e.dataTransfer.getData("application/json"));
    onDrop(draggedData.id, id, draggedData.card, card);
  };

  return (
    <motion.div
      layout
      transition={{ type: "spring", stiffness: 350, damping: 25 }}
      className={`${className} ${isOrderActive ? 'tw-cursor-grab active:tw-cursor-grabbing' : ''}`}
      style={isOrderActive ? { order: parseInt(id) } : {}}
      draggable={isOrderActive}
      onDragStart={isOrderActive ? handleDragStart : undefined}
      onDragOver={isOrderActive ? (e) => e.preventDefault() : undefined}
      onDrop={isOrderActive ? handleDrop : undefined}
      whileHover={isOrderActive ? { scale: 1.0 } : {}}
      whileTap={isOrderActive ? { scale: 0.98, zIndex: 10 } : {}}
    >
      {children}
    </motion.div>
  );
};