import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getAccount, getAllModem, getAreaById, getCircleById, getDivisionById, getGaById, getGSMDataById, getRolesById, getSubdevisionById, gsmModemMapFilterData } from '../../Common/Utils';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { GoogleMap, InfoWindowF, MarkerClustererF, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import moment from 'moment';
import clusterIconImage from '../../assets/SVGs/m1.png';
import mapRedicon from '../../assets/img/triangle-red.svg'
import mapBlueicon from '../../assets/img/triangle-blue.svg'
import mapicon from '../../assets/img/triangle-green.svg'
import GoogleLoader from '../../Common/Loaders/GoogleLoader';
import { Button } from '../../Common/ui/button';
import { Filter, Loader, SquareArrowOutUpRight, X } from 'lucide-react';
import { cn } from '../../lib/utils';
import ReactSelect from '../../Common/ui/react-select/react-select';
import MultiInput from '../../Common/MultiInput/MultiInput';
import signal2 from '../../assets/img/secondsignal.png'
import signal3 from '../../assets/img/signal3.png'
import signal4 from '../../assets/img/signal4.png'
import signal5 from '../../assets/img/signal5.png'

export default function Map() {
    const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
    const [searchParams] = useSearchParams();
    const params = useLocation();
    const history = useNavigate()
    const [GSMID, setGSMID] = useState(null);
    const [filterObj, setFilterObj] = useState({ Status: '1' });
    const [sendfilterObj, setSendFilterObj] = useState({ Status: '1' });
    const [lastSeen, setLastSeenS] = useState(24);
    const [lastseenIndate, setlastseenIndate] = useState({});
    const [defaultDate, setDefaultDate] = useState(moment().add(-moment().utcOffset(), "m").format("DD-MM-YYYY HH:mm:ss"));
    const [gsmInfo, setGsmInfo] = useState(null)
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const [AccounData, setAccountData] = useState([]);
    const [GaData, setGaData] = useState([]);
    const [CircleData, setCircleData] = useState([]);
    const [DivisionData, setDivisionData] = useState([]);
    const [SubdevisionData, setSubdevisionData] = useState([]);
    const [AreaData, setAreaData] = useState([]);

    const [AccountKey, setAccountKey] = useState([])

    const [Profile, setProfile] = useState({
        accountuser: false,
        gauser: false,
        circleuser: false,
        areauser: false,
        divisionuser: false,
        subDivisionuser: false,
    })

    const [consumer, setConsumer] = useState({
        checktext1: false,
        checktext2: false,
        checktext3: false,
        checktext4: false,
        checktext5: false,
        checktext6: false,
        checktext7: false,
        checktextCS: true,
        checktextMP: false,
        checktextMOP: false,
        checktextTA: false,
        checktextInTS: false,
        checktextLSs: false,
        Consumer_Type: false,
        checktextDRTS: false
    })

    const [modem, setModem] = useState({
        checktextRSSI: false,
        checktextLS: false,
        checktextBP: false,
    })

    const [modemStart, setModemState] = useState({
        Between: false,
        lessThan: false,
        greaterThan: false
    })

    const [DRState, setDRState] = useState({
        Between: false,
        lessThan: false,
        greaterThan: false
    })

    const [betteryPr, setbetteryPr] = useState({
        Between: false,
        lessThan: false,
        greaterThan: false
    })

    const [last, setLastSeen] = useState({
        LessEqual: false,
        GreaterEqual: false
    })

    const consumers = [
        { value: 'all', label: 'All' },
        { value: '1', label: 'Active' },
        { value: '0', label: 'InActive' },
        { value: '-1', label: 'Pending Configurations' },
        { value: '2', label: 'Under Maintenance' },
        { value: '3', label: 'Stand By' }
    ]

    const activeConsumerStatusOptions = [
        { value: '1', label: 'Live' },
        { value: '2', label: 'Temporary Disconnection' }
    ]

    const inActiveConsumerStatusOptions = [
        { value: '3', label: 'Permanent Disconnection' }
    ]

    const dataWise = [
        { value: "Between", label: 'Is between' },
        { value: "LessEqual", label: 'Is Less than Equel to' },
        { value: "GreaterEqual", label: "Is Greater than Equel to" }
    ]

    const lastSeenWise = [
        { value: "LessEqual", label: 'Less than' },
        { value: "GreaterEqual", label: 'Greater than' }
    ]

    const lastSeenWiseOption = [
        { value: "Hours", label: 'Hours' },
        { value: "Minutes", label: 'Minutes' }
    ]

    useEffect(() => {
        const params = Object.fromEntries(searchParams);
        if (params.last_seen) {
            setLastSeenS(parseInt(params.last_seen) || 24);
        } else if (params.date) {
            setlastseenIndate(JSON.parse(params.date));
        } else {
            setGSMID(Object.keys(params)[0]);
        }
    }, [searchParams]);

    useEffect(() => {
        if (params?.state?.detail) {
            setFilterObj({ ...params?.state?.detail })
        } else {
            setFilterObj({ ...filterObj, Status: '1' })
        }
    }, []);

    const { isLoading: isLoadingPermission, error: errorPermission, data: permission } = useQuery({
        queryKey: ['permission', userInfo?.role],
        queryFn: async () => {
            const data = await getRolesById(userInfo?.role)
            if (data.success === true) {
                const obj = data.data.data[0];
                return {
                    ...obj,
                    roles_permission_category0: JSON.parse(
                        obj.roles_permission_category0 || '{}'
                    ),
                    roles_permission_category5: JSON.parse(
                        obj.roles_permission_category5 || '{}'
                    ),
                    roles_permission_category2: JSON.parse(
                        obj.roles_permission_category2 || '{}'
                    ),
                    roles_permission_category3: JSON.parse(
                        obj.roles_permission_category3 || '{}'
                    ),
                    roles_permission_category1: JSON.parse(
                        obj.roles_permission_category1 || '{}'
                    ),
                    roles_permission_category4: JSON.parse(
                        obj.roles_permission_category4 || '{}'
                    ),
                    roles_other_permission: JSON.parse(
                        obj.roles_other_permission || '{}'
                    ),
                }

            } else {
                return null
            }
        },
        enabled: !!userInfo?.role
    });

    const { isLoading: gsmDataLoading, error: gsmDataError, data: GSMData } = useQuery({
        queryKey: ['gsmData', GSMID],
        queryFn: async () => {
            const response = await getGSMDataById(GSMID);
            if (response?.success) {
                const objGSM = response?.data?.data[0];
                if (objGSM) {
                    const parsedData = {
                        ...objGSM,
                        ADDRESS: JSON.parse(objGSM?.address || "{}"),
                        CONTACT_PERSON: JSON.parse(objGSM?.contact_person || "[]"),
                        INSTALLATION_DETAILS: JSON.parse(objGSM?.installation_details || "{}"),
                        UNIQUE_TABLE_NAME: JSON.parse(objGSM?.unique_table_name || "{}"),
                        HEARTBEAT_LAST_DATA: JSON.parse(objGSM?.heartbeat_last_data || "{}")
                    };
                    return parsedData;
                }
            }
            return null;
        },
        enabled: !!GSMID,
    });

    const { isLoading: gsmMapDataLoading, error: gsmMapDataError, data: mapInfo } = useQuery({
        queryKey: ['gsmData', sendfilterObj],
        queryFn: async () => {
            const infoRolesObj = { ROLES: userInfo?.role, CATEGORY0_view: permission?.roles_permission_category0?.CATEGORY0_view, CATEGORY1_view: permission?.roles_permission_category1?.CATEGORY1_view, CATEGORY2_view: permission?.roles_permission_category2?.CATEGORY2_view, CATEGORY3_view: permission?.roles_permission_category3?.CATEGORY3_view, CATEGORY4_view: permission?.roles_permission_category4?.CATEGORY4_view, CATEGORY5_view: permission?.roles_permission_category5?.CATEGORY5_view }

            const response = await gsmModemMapFilterData({ ...sendfilterObj, ...infoRolesObj })
            if (response?.success) {
                return response.data.data;
            }
            return null;
        },
        enabled: !!permission,
    });

    const { data: accountData, isLoading: accountLoading } = useQuery({
        queryKey: ['AccountData'],
        queryFn: async () => {
            const response = await getAccount();
            if (response?.success) {
                const optionArray = response?.data?.data.filter((data) => (
                    permission?.roles_permission_category0?.CATEGORY0_view?.filter((items) =>
                        (items === data.UNIQUE_ID)).length > 0 ||
                    userInfo?.role === 'ROLE1650614500708'
                ));
                setAccountKey(response?.data?.data)
                return optionArray.map(item => ({
                    value: item?.UNIQUE_ID,
                    label: item?.CATEGORY0
                }));

            }
            return [];
        },
        enabled: !!permission,
        onError: (error) => {
            toast.error(error.message || 'Failed to fetch GA data');
            setGaData([]);
        }
    });

    const { data: gaData, isLoading: gaLoading } = useQuery({
        queryKey: ['gaData', filterObj?.CATEGORY0],
        queryFn: async () => {
            const response = await getGaById(filterObj?.CATEGORY0);
            if (response?.success) {
                const optionArray = response?.data?.data.filter((data) => (
                    permission?.roles_permission_category1?.CATEGORY1_view?.filter((items) =>
                        (items === data.CATEGORY1_UNIQUE_ID)).length > 0 ||
                    userInfo?.role === 'ROLE1650614500708'
                ));
                return optionArray.map(item => ({
                    value: item?.CATEGORY1_UNIQUE_ID,
                    label: item?.CATEGORY1_NAME
                }));
            }
            return [];
        },
        enabled: !!filterObj?.CATEGORY0,
        onError: (error) => {
            toast.error(error.message || 'Failed to fetch GA data');
            setGaData([]);
        }
    });

    const { data: circleData, isLoading: circleLoading } = useQuery({
        queryKey: ['circleData', filterObj?.CATEGORY1],
        queryFn: async () => {
            const response = await getCircleById(filterObj?.CATEGORY1);
            if (response?.success) {
                const optionArray = response?.data?.data.filter((data) => (
                    permission?.roles_permission_category2?.CATEGORY2_view?.filter((items) =>
                        (items === data.CATEGORY2_UNIQUE_ID)).length > 0 ||
                    userInfo?.role === 'ROLE1650614500708'
                ));
                return optionArray.map(item => ({
                    value: item?.CATEGORY2_UNIQUE_ID,
                    label: item?.CATEGORY2_NAME
                }));
            }
            return [];
        },
        enabled: !!filterObj?.CATEGORY1,
        onError: (error) => {
            toast.error(error.message || 'Failed to fetch Circle data');
            setCircleData([]);
        }
    });

    const { data: divisionData, isLoading: divisionLoading } = useQuery({
        queryKey: ['divisionData', filterObj?.CATEGORY2],
        queryFn: async () => {
            const response = await getDivisionById(filterObj?.CATEGORY2);
            if (response?.success) {
                const optionArray = response?.data?.data.filter((data) => (
                    permission?.roles_permission_category3?.CATEGORY3_view?.filter((items) =>
                        (items === data.CATEGORY3_UNIQUE_ID)).length > 0 ||
                    userInfo?.role === 'ROLE1650614500708'
                ));
                return optionArray.map(item => ({
                    value: item?.CATEGORY3_UNIQUE_ID,
                    label: item?.CATEGORY3_NAME
                }));
            }
            return [];
        },
        enabled: !!filterObj?.CATEGORY2,
        onError: (error) => {
            toast.error(error.message || 'Failed to fetch Division data');
            setDivisionData([]);
        }
    });

    const { data: subDivisionData, isLoading: subDivisionLoading } = useQuery({
        queryKey: ['subDivisionData', filterObj?.CATEGORY3],
        queryFn: async () => {
            const response = await getSubdevisionById(filterObj?.CATEGORY3);
            if (response?.success) {
                const optionArray = response?.data?.data.filter((data) => (
                    permission?.roles_permission_category4?.CATEGORY4_view?.filter((items) =>
                        (items === data.CATEGORY4_UNIQUE_ID)).length > 0 ||
                    userInfo?.role === 'ROLE1650614500708'
                ));
                return optionArray.map(item => ({
                    value: item?.CATEGORY4_UNIQUE_ID,
                    label: item?.CATEGORY4_NAME
                }));
            }
            return [];
        },
        enabled: !!filterObj?.CATEGORY3,
        onError: (error) => {
            toast.error(error.message || 'Failed to fetch Sub Division data');
            setSubdevisionData([]);
        }
    });

    const { data: areaData, isLoading: areaLoading } = useQuery({
        queryKey: ['areaData', filterObj?.CATEGORY4],
        queryFn: async () => {
            const response = await getAreaById(filterObj?.CATEGORY4);
            if (response?.success) {
                const optionArray = response?.data?.data.filter((data) => (
                    permission?.roles_permission_category5?.CATEGORY5_view?.filter((items) =>
                        (items === data.UNIQUE_ID)).length > 0 ||
                    userInfo?.role === 'ROLE1650614500708'
                ));
                return optionArray.map(item => ({
                    value: item?.UNIQUE_ID,
                    label: item?.CATEGORY5_NAME
                }));
            }
            return [];
        },
        enabled: !!filterObj?.CATEGORY4,
        onError: (error) => {
            toast.error(error.message || 'Failed to fetch Area data');
            setAreaData([]);
        }
    });

    const { data: modemData, isLoading: modemDataLoading, error: modemDataError } = useQuery({
        queryKey: ['modemData'],
        queryFn: () => getAllModem(true)
    });

    useEffect(() => {
        if (errorPermission) {
            toast.error('Error fetching Role: ', JSON.stringify(errorPermission));
        }
        if (modemDataError) {
            toast.error('Error fetching Modem Data: ', JSON.stringify(modemDataError));
        }
        if (gsmDataError) {
            toast.error('Error fetching GSM Data: ', JSON.stringify(gsmDataError));
        }
        if (gsmMapDataError) {
            toast.error('Error fetching GSM Data: ', JSON.stringify(gsmMapDataError));
        }
    }, [errorPermission, modemDataError, gsmDataError, gsmMapDataError]);

    const modemOptions = useMemo(() => {
        if (!modemData?.data?.data) return [];
        return modemData?.data?.data.map((item) => ({
            value: item?.MODEM_UNIQUE_ID,
            label: item?.MODEM_PROFILE_NAME,
        }));
    }, [modemData]);

    useEffect(() => {
        if (!filterObj?.CATEGORY0) {
            setGaData([]);
            setCircleData([]);
            setDivisionData([]);
            setSubdevisionData([]);
            setAreaData([]);
        }
    }, [filterObj.CATEGORY0]);

    useEffect(() => {
        if (!filterObj?.CATEGORY1) {
            setCircleData([]);
            setDivisionData([]);
            setSubdevisionData([]);
            setAreaData([]);
        }
    }, [filterObj.CATEGORY1]);

    useEffect(() => {
        if (!filterObj?.CATEGORY2) {
            setDivisionData([]);
            setSubdevisionData([]);
            setAreaData([]);
        }
    }, [filterObj.CATEGORY2]);

    useEffect(() => {
        if (!filterObj?.CATEGORY3) {
            setSubdevisionData([]);
            setAreaData([]);
        }
    }, [filterObj.CATEGORY3]);

    useEffect(() => {
        if (!filterObj?.CATEGORY4) {
            setAreaData([]);
        }
    }, [filterObj.CATEGORY4]);

    const handleChangeS = (e) => {
        if (e.target.checked) {
            if (Profile.hasOwnProperty(e.target.id)) {
                setProfile({ ...Profile, [e.target.id]: true })
                setFilterObj({ ...filterObj, [e.target.name]: [] })
            }
            if (consumer.hasOwnProperty(e.target.id)) {
                setConsumer({ ...consumer, [e.target.id]: true })
                setFilterObj({ ...filterObj, [e.target.name]: '' })
            }

            if (modem.hasOwnProperty(e.target.id)) {
                setModem({ ...modem, [e.target.id]: true })
            }

            if (e.target.id == 'checktextLS') {
                setFilterObj({ ...filterObj, [e.target.name]: {} })
            }
            if (e.target.id == 'checktextRSSI') {
                setFilterObj({ ...filterObj, [e.target.name]: {} })
            }
            if (e.target.id == 'checktextInTS') {
                setFilterObj({ ...filterObj, [e.target.name]: {} })
            }
            if (e.target.id == 'checktextBP') {
                setFilterObj({ ...filterObj, [e.target.name]: {} })
            }
            if (e.target.id == 'checktextDRTS') {
                setFilterObj({ ...filterObj, [e.target.name]: {} })
            }

        } else {

            if (Profile.hasOwnProperty(e.target.id)) {
                setProfile({ ...Profile, [e.target.id]: false })
                delete filterObj[e.target.name]
            }
            if (consumer.hasOwnProperty(e.target.id)) {
                setConsumer({ ...consumer, [e.target.id]: false })
                delete filterObj[e.target.name]
                if (e.target.id == 'checktextDRTS') {
                    setDRState({ ...DRState, greaterThan: false, lessThan: false, Between: false })
                }
            }

            if (modem.hasOwnProperty(e.target.id)) {
                setModem({ ...modem, [e.target.id]: false })
                if (e.target.id == 'checktextRSSI') {
                    delete filterObj[e.target.name]
                    setModemState({ ...modemStart, greaterThan: false, lessThan: false, Between: false })
                }
                if (e.target.id == 'checktextBP') {
                    delete filterObj[e.target.name]
                    setbetteryPr({ ...betteryPr, greaterThan: false, lessThan: false, Between: false })
                }
                if (e.target.id == 'checktextLS') {
                    console.log(e.target.id)
                    delete filterObj[e.target.name]
                    setLastSeen({ ...last, greaterThan: false, lessThan: false })
                }
            }
        }
    }

    const onSearchMultiFilterJson = (newSearchTerms, json_name) => {
        setFilterObj({ ...filterObj, [json_name]: newSearchTerms })
    };

    const onItemClearedFilterJson = (clearedIndex, json_name) => {
        const newSearchTerms = filterObj[json_name]?.filter((_, index) => index !== clearedIndex);
        onSearchMultiFilterJson(newSearchTerms, json_name);
    };

    const onClearAllItemsFilterJson = (arr, json_name) => {
        onSearchMultiFilterJson([], json_name);
    };

    const handleSelectChange = (e) => {
        const stateUpdate = Object.keys(modemStart)
        setModemState(stateUpdate.reduce((obj, data) => ({ ...obj, [e.value]: true }), {}))
        setFilterObj({ ...filterObj, RSSI: stateUpdate.reduce((obj, data) => ({ ...obj, [e.value]: "True" }), {}) })
    };

    const handleBetteryPr = (e) => {
        const BpUpdate = Object.keys(betteryPr)
        setbetteryPr(BpUpdate.reduce((obj, data) => ({ ...obj, [e.value]: true }), {}))
        setFilterObj({ ...filterObj, BAT_PER: BpUpdate.reduce((obj, data) => ({ ...obj, [e.value]: "True" }), {}) })

    };

    const handleLastSeen = (e) => {
        const lastSeenUpdate = Object.keys(last)
        setLastSeen(lastSeenUpdate.reduce((obj, data) => ({ ...obj, [e.value]: true }), {}))
        setFilterObj({ ...filterObj, Last_Seen: { ...filterObj.Last_Seen, [e.value]: 'True' } })

    };

    const ongetAllMapDataInGSMFilter = async (info, data_info) => {
        var perameter_obj = {}
        if (info == 'true') {
            perameter_obj = { ...data_info, ROLES: userInfo?.role, CATEGORY0_view: permission?.roles_permission_category0?.CATEGORY0_view, CATEGORY1_view: permission?.roles_permission_category1?.CATEGORY1_view, CATEGORY2_view: permission?.roles_permission_category2?.CATEGORY2_view, CATEGORY3_view: permission?.roles_permission_category3?.CATEGORY3_view, CATEGORY4_view: permission?.roles_permission_category4?.CATEGORY4_view, CATEGORY5_view: permission?.roles_permission_category5?.CATEGORY5_view, roles: userInfo?.ROLE }
        } else {
            perameter_obj = { ...filterObj, ROLES: userInfo?.role, CATEGORY0_view: permission?.roles_permission_category0?.CATEGORY0_view, CATEGORY1_view: permission?.roles_permission_category1?.CATEGORY1_view, CATEGORY2_view: permission?.roles_permission_category2?.CATEGORY2_view, CATEGORY3_view: permission?.roles_permission_category3?.CATEGORY3_view, CATEGORY4_view: permission?.roles_permission_category4?.CATEGORY4_view, CATEGORY5_view: permission?.roles_permission_category5?.CATEGORY5_view, roles: userInfo?.ROLE }
        }
        setSendFilterObj(perameter_obj)
    }

    const onResetFilter = () => {
        setProfile({
            accountuser: false,
            gauser: false,
            circleuser: false,
            areauser: false,
            divisionuser: false,
            subDivisionuser: false,
        })

        setConsumer({
            checktext1: false,
            checktext2: false,
            checktext3: false,
            checktext4: false,
            checktext5: false,
            checktext6: false,
            checktext7: false,
            checktextCS: true,
            checktextMP: false,
            checktextMOP: false,
            checktextTA: false,
            checktextInTS: false,
            checktextLSs: false,
            Consumer_Type: false,
            checktextDRTS: false
        })

        setModem({
            checktextRSSI: false,
            checktextLS: false,
            checktextBP: false,
        })
        setFilterObj({ Status: '1' });
        setSendFilterObj({ Status: '1' });
        setIsFilterOpen(false)
    }

    const onShowGSMConsumerInfo = (info) => {
        if (isFilterOpen) {
            setIsFilterOpen(false)
        }
        setGsmInfo(info)
    }

    const onCloseGSMConsumerInfo = () => {
        setGsmInfo(null)
    }

    return (
        <>
            <div className={cn('tw-absolute tw-z-50 tw-top-0 tw-left-0  tw-h-[100dvh] tw-overflow-auto tw-w-[550px] tw-bg-white p-2', isFilterOpen ? 'tw-visible' : 'tw-hidden')} >
                <div className='tw-py-2 tw-flex tw-justify-end' >
                    <Button onClick={() => setIsFilterOpen(false)} className=' ' variant={"destructive"} size={"icon"} >
                        <X size={16} />
                    </Button>
                </div>

                <div>
                    <fieldset className="customLegend">
                        <legend >Group Wise Filter</legend>
                        <div className=" tw-pl-4" >
                            <div className=" tw-flex tw-flex-col tw-gap-2 ">

                                <div className="form-row tw-items-center">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" id="accountuser" name="CATEGORY0" className="custom-control-input " value="account" checked={Profile.accountuser} onChange={handleChangeS} />
                                        <label className="custom-control-label" htmlFor="accountuser">CATEGORY0</label>
                                    </div>
                                    {Profile.accountuser && (<div className="form-group col-md-8 mb-0" >
                                        <ReactSelect
                                            isMulti
                                            name={'Account'}
                                            options={accountData}
                                            className="basic-multi-select"
                                            onChange={(selectedOption) => { setFilterObj({ ...filterObj, CATEGORY0: selectedOption?.map((item) => (item.value)) }) }}
                                            value={accountData?.filter((item) => filterObj?.CATEGORY0?.includes(item.value)) || []}
                                        />

                                    </div>)}
                                </div>

                                <div className="form-row tw-items-center">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" id="gauser" name="CATEGORY1" className="custom-control-input" value="ga" checked={Profile.gauser} onChange={handleChangeS} />
                                        <label className="custom-control-label" htmlFor="gauser">CATEGORY1</label>
                                    </div>
                                    {Profile.gauser && (<div className="form-group col-md-8 mb-0" >
                                        <ReactSelect
                                            isMulti
                                            name={'GA'}
                                            options={gaData}
                                            className="basic-multi-select"
                                            onChange={(selectedOption) => { setFilterObj({ ...filterObj, CATEGORY1: selectedOption?.map((item) => (item.value)) }) }}
                                            value={gaData?.filter((item) => filterObj?.CATEGORY1?.includes(item.value)) || []}
                                        />

                                    </div>)}
                                </div>

                                <div className="form-row tw-items-center">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" id="circleuser" name="CATEGORY2" className="custom-control-input " value="circle" checked={Profile.circleuser} onChange={handleChangeS} />
                                        <label className="custom-control-label" htmlFor="circleuser">CATEGORY2</label>
                                    </div>
                                    {Profile.circleuser && (<div className="form-group col-md-8 mb-0" >
                                        <ReactSelect
                                            isMulti
                                            name={'Circle'}
                                            options={circleData}
                                            className="basic-multi-select"
                                            onChange={(selectedOption) => { setFilterObj({ ...filterObj, CATEGORY2: selectedOption?.map((item) => (item.value)) }) }}
                                            value={circleData?.filter((item) => filterObj?.CATEGORY2?.includes(item.value)) || []}
                                        />

                                    </div>)}
                                </div>

                                <div className="form-row tw-items-center">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" id="divisionuser" name="CATEGORY3" className="custom-control-input " value="CATEGORY3" checked={Profile.divisionuser} onChange={handleChangeS} />
                                        <label className="custom-control-label" htmlFor="divisionuser">CATEGORY3</label>
                                    </div>
                                    {Profile.divisionuser && (<div className="form-group col-md-8 mb-0" >
                                        <ReactSelect
                                            isMulti
                                            name={'Division'}
                                            options={divisionData}
                                            className="basic-multi-select"
                                            onChange={(selectedOption) => { setFilterObj({ ...filterObj, CATEGORY3: selectedOption?.map((item) => (item.value)) }) }}
                                            value={divisionData?.filter((item) => filterObj?.CATEGORY3?.includes(item.value)) || []}
                                        />

                                    </div>)}
                                </div>

                                <div className="form-row tw-items-center">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" id="subDivisionuser" name="CATEGORY4" className="custom-control-input " value="subdivision" checked={Profile.subDivisionuser} onChange={handleChangeS} />
                                        <label className="custom-control-label" htmlFor="subDivisionuser">CATEGORY4</label>
                                    </div>
                                    {Profile.subDivisionuser && (<div className="form-group col-md-8 mb-0" >
                                        <ReactSelect
                                            isMulti
                                            name={'SubDivisionuser'}
                                            options={subDivisionData}
                                            className="basic-multi-select"
                                            onChange={(selectedOption) => { setFilterObj({ ...filterObj, CATEGORY4: selectedOption?.map((item) => (item.value)) }) }}
                                            value={subDivisionData?.filter((item) => filterObj?.CATEGORY4?.includes(item.value)) || []}
                                        />

                                    </div>)}
                                </div>

                                <div className="form-row tw-items-center">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" id="areauser" name="CATEGORY5" className="custom-control-input " value="account" checked={Profile.areauser} onChange={handleChangeS} />
                                        <label className="custom-control-label" htmlFor="areauser">CATEGORY5</label>
                                    </div>
                                    {Profile.areauser && (<div className="form-group col-md-8 mb-0" >
                                        <ReactSelect
                                            isMulti
                                            name={'Area'}
                                            options={areaData}
                                            className="basic-multi-select"
                                            onChange={(selectedOption) => { setFilterObj({ ...filterObj, CATEGORY5: selectedOption?.map((item) => (item.value)) }) }}
                                            value={areaData?.filter((item) => filterObj?.CATEGORY5?.includes(item.value)) || []}
                                        />

                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </fieldset>

                    <fieldset className="customLegend">
                        <legend >Consumer Info Wise Filter</legend>
                        <div className=" tw-pl-4" >
                            <div className=" tw-flex tw-flex-col tw-gap-2 ">

                                <div className="form-row tw-items-center">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" id="checktext3" name="Modem_Serial_No" className="custom-control-input" value="modemserial" checked={consumer.checktext3} onChange={handleChangeS} />
                                        <label className="custom-control-label" htmlFor="checktext3">Modem Serial No</label>
                                    </div>
                                    {consumer.checktext3 && <div className="col-md-8" > <MultiInput
                                        placeholder="search and enter..."
                                        onChange={(arr) => onSearchMultiFilterJson(arr, "Modem_Serial_No")}
                                        maxItems={null}
                                        onClear={(arr) => onItemClearedFilterJson(arr, "Modem_Serial_No")}
                                        onClearAll={(arr) => onClearAllItemsFilterJson(arr, "Modem_Serial_No")}
                                        className='!tw-w-full'
                                        value={filterObj?.Modem_Serial_No}
                                    /></div>}

                                </div>

                                <div className="form-row tw-items-center">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" id="checktext4" name="Panel_Serial_No" className="custom-control-input" value="panelserno" checked={consumer.checktext4} onChange={handleChangeS} />
                                        <label className="custom-control-label" htmlFor="checktext4">Panel Serial No</label>
                                    </div>
                                    {consumer.checktext4 && <div className="col-md-8" ><MultiInput
                                        placeholder="search and enter..."
                                        onChange={(arr) => onSearchMultiFilterJson(arr, "Panel_Serial_No")}
                                        maxItems={null} // Allow any number of items
                                        onClear={(arr) => onItemClearedFilterJson(arr, "Panel_Serial_No")}
                                        onClearAll={(arr) => onClearAllItemsFilterJson(arr, "Panel_Serial_No")}
                                        className='!tw-w-full'
                                        value={filterObj?.Panel_Serial_No}
                                    /></div>}

                                </div>

                                <div className="form-row tw-items-center">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" id="checktext5" name="Unique_ID" className="custom-control-input" value="cnuniqueid" checked={consumer.checktext5} onChange={handleChangeS} />
                                        <label className="custom-control-label" htmlFor="checktext5">Consumer Unique Id</label>
                                    </div>
                                    {consumer.checktext5 && <div className="col-md-8" ><MultiInput
                                        placeholder="search and enter..."
                                        onChange={(arr) => onSearchMultiFilterJson(arr, "Unique_ID")}
                                        maxItems={null} // Allow any number of items
                                        onClear={(arr) => onItemClearedFilterJson(arr, "Unique_ID")}
                                        onClearAll={(arr) => onClearAllItemsFilterJson(arr, "Unique_ID")}
                                        className='!tw-w-full'
                                        value={filterObj?.Unique_ID}
                                    /></div>}
                                </div>

                                <div className="form-row tw-items-center">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" id="checktext7" name="IMEI" className="custom-control-input" value="allimei" checked={consumer.checktext7} onChange={handleChangeS} />
                                        <label className="custom-control-label" htmlFor="checktext7">imei</label>
                                    </div>
                                    {consumer.checktext7 && <div className="col-md-8" ><MultiInput
                                        placeholder="search and enter..."
                                        onChange={(arr) => onSearchMultiFilterJson(arr, "IMEI")}
                                        maxItems={null} // Allow any number of items
                                        onClear={(arr) => onItemClearedFilterJson(arr, "IMEI")}
                                        onClearAll={(arr) => onClearAllItemsFilterJson(arr, "IMEI")}
                                        className='!tw-w-full'
                                        value={filterObj?.IMEI}
                                    /></div>}
                                </div>

                                <div className="form-row tw-items-center">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" id="checktextCS" name="Status" className="custom-control-input" value="filterstatus" checked={consumer.checktextCS} onChange={handleChangeS} />
                                        <label className="custom-control-label" htmlFor="checktextCS">Consumer Status</label>
                                    </div>
                                    {consumer.checktextCS && (<div className="form-group col-md-4 checktextCS filterstatus mb-0">
                                        <ReactSelect
                                            name={'Status'}
                                            options={consumers}
                                            className="basic-multi-select"
                                            onChange={(e) => {
                                                if (!e && e?.value != 1 && e?.value != 0) {
                                                    delete filterObj["SubStatus"]
                                                }
                                                setFilterObj({ ...filterObj, Status: e?.value || '' })
                                            }}
                                            value={consumers.filter((item) => (item?.value == filterObj?.Status)) || []}
                                        />
                                    </div>)}
                                    {consumer.checktextCS && filterObj?.Status && filterObj?.Status == 1 && <div className="form-group col-md-4 checktextCS filterstatus mb-0" >
                                        <ReactSelect
                                            name={'Status'}
                                            options={activeConsumerStatusOptions}
                                            className="basic-multi-select"
                                            onChange={(e) => { setFilterObj({ ...filterObj, SubStatus: e?.value || '' }) }}
                                            value={activeConsumerStatusOptions?.filter((item) => filterObj?.SubStatus?.includes(item.value)) || []}
                                        />
                                    </div>}
                                    {consumer.checktextCS && filterObj?.Status && filterObj?.Status == 0 && <div className="form-group col-md-4 checktextCS filterstatus mb-0" >
                                        <ReactSelect
                                            name={'Status'}
                                            options={inActiveConsumerStatusOptions}
                                            className="basic-multi-select"
                                            onChange={(e) => { setFilterObj({ ...filterObj, SubStatus: e?.value || '' }) }}
                                            value={inActiveConsumerStatusOptions?.filter((item) => filterObj?.SubStatus?.includes(item.value)) || []}
                                        />
                                    </div>}
                                </div>

                                <div className="form-row tw-items-center">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" id="checktextMOP" name="Modem_Profile" className="custom-control-input" value="modemprofile" checked={consumer.checktextMOP} onChange={handleChangeS} />
                                        <label className="custom-control-label" htmlFor="checktextMOP">Modem Profile</label>
                                    </div>
                                    {consumer.checktextMOP && (<div className="form-group col-md-8 mb-0" >
                                        <ReactSelect
                                            isMulti
                                            name={'Modem_Profile'}
                                            options={modemOptions}
                                            className="basic-multi-select"
                                            onChange={(selectedOption) => { setFilterObj({ ...filterObj, Modem_Profile: selectedOption?.map((item) => (item.value)) }) }}
                                            value={modemOptions?.filter((item) => filterObj?.Modem_Profile?.includes(item.value)) || []}
                                        />

                                    </div>)}
                                </div>

                                <div className="form-row tw-items-center">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" id="checktextTA" name="Tags" className="custom-control-input " value="tagsvalue" checked={consumer.areauser} onChange={handleChangeS} />
                                        <label className="custom-control-label" htmlFor="checktextTA">Tags</label>
                                    </div>
                                    {consumer.checktextTA && (<div className="form-group col-md-8 mb-0" >
                                        <ReactSelect
                                            isMulti
                                            name={'Tags'}
                                            options={[]}
                                            className="basic-multi-select"
                                            onChange={(selectedOption) => { setFilterObj({ ...filterObj, Tags: selectedOption?.map((item) => (item.value)) }) }}
                                            value={[]}
                                        />

                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </fieldset>

                    <fieldset className="customLegend">
                        <legend >Modem Data Wise Filter</legend>
                        <div className="tw-pl-4" >
                            <div className=" tw-flex tw-flex-col tw-gap-2 ">

                                <div className="tw-grid tw-grid-cols-11 tw-items-center tw-gap-1">
                                    <div className="custom-control custom-checkbox tw-col-span-2" >
                                        <input type="checkbox" id="checktextRSSI" name="RSSI" className="custom-control-input" value="rssival" checked={modem.checktextRSSI} onChange={handleChangeS} />
                                        <label className="custom-control-label" htmlFor="checktextRSSI">RSSI</label>
                                    </div>

                                    {modem.checktextRSSI && (<div className=" checktextRSSI length rssival mb-0 tw-col-span-4" >
                                        <ReactSelect
                                            name={'RSSI'}
                                            options={dataWise}
                                            className="basic-multi-select"
                                            onChange={handleSelectChange}
                                            value={dataWise?.filter((v) => Object?.keys(filterObj?.RSSI || {})?.includes(v?.value)) || []}
                                        />
                                    </div>)}

                                    {modemStart.Between && modem.checktextRSSI && (<div className="  checktext14 mb-0  tw-col-span-2" >
                                        <input type="text" className="form-control" name="filter_rssi_from" id="filter_rssi_from" placeholder="From" onChange={(e) => { setFilterObj({ ...filterObj, RSSI: { ...filterObj.RSSI, From: e.target.value } }) }} value={filterObj.RSSI?.From || ""} />
                                    </div>)}

                                    {modemStart.Between && modem.checktextRSSI && (<div className="  checktext13 mb-0  tw-col-span-2" >
                                        <input type="text" className="form-control" name="filter_rssi_to" id="filter_rssi_to" placeholder="To" onChange={(e) => { setFilterObj({ ...filterObj, RSSI: { ...filterObj.RSSI, To: e.target.value } }) }} value={filterObj.RSSI?.To || ""} />
                                    </div>)}

                                    {modemStart.LessEqual && modem.checktextRSSI && (<div className="  checktext13 mb-0 tw-col-span-2" >
                                        <input type="text" className="form-control" name="filter_rssi_less_then" id="filter_rssi_less_then" placeholder="less than..." onChange={(e) => { setFilterObj({ ...filterObj, RSSI: { ...filterObj.RSSI, Value: e.target.value } }) }} value={filterObj.RSSI?.Value || ""} />
                                    </div>)}

                                    {modemStart.GreaterEqual && modem.checktextRSSI && (<div className="  checktext13 mb-0 tw-col-span-2" >
                                        <input type="text" className="form-control" name="filter_rssi_greater_then" id="filter_rssi_greater_then" placeholder="greter than..." onChange={(e) => { setFilterObj({ ...filterObj, RSSI: { ...filterObj.RSSI, Value: e.target.value } }) }} value={filterObj.RSSI?.Value || ""} />
                                    </div>)}

                                </div>

                                <div className="tw-grid tw-grid-cols-12 tw-w-full tw-items-center tw-gap-1">
                                    <div className="custom-control custom-checkbox tw-col-span-3" >
                                        <input type="checkbox" id="checktextBP" name="BAT_PER" className="custom-control-input" value="BAT_PER" checked={modem.checktextBP} onChange={handleChangeS} />
                                        <label className="custom-control-label" htmlFor="checktextBP">Battery Percentage</label>
                                    </div>

                                    {modem.checktextBP && (<div className="tw-col-span-4 checktextBP length batperval" >
                                        <ReactSelect
                                            name={'Battery Percentage'}
                                            options={dataWise}
                                            className="basic-multi-select"
                                            onChange={handleBetteryPr}
                                            value={dataWise?.filter((v) => Object.keys(filterObj?.BAT_PER || {})?.includes(v?.value)) || []}
                                        />

                                    </div>)}

                                    {betteryPr.Between && modem.checktextBP && (<div className="mb-0  tw-col-span-2 checktext14" >
                                        <input type="text" className="form-control" name="filter_rssi_from" id="filter_rssi_from" placeholder="From" onChange={(e) => { setFilterObj({ ...filterObj, BAT_PER: { ...filterObj.BAT_PER, From: e.target.value } }) }} value={filterObj.BAT_PER?.From || ""} />
                                    </div>)}
                                    {betteryPr.Between && modem.checktextBP && (<div className="mb-0  tw-col-span-2 checktext13" >
                                        <input type="text" className="form-control" name="filter_rssi_to" id="filter_rssi_to" placeholder="To" onChange={(e) => { setFilterObj({ ...filterObj, BAT_PER: { ...filterObj.BAT_PER, To: e.target.value } }) }} value={filterObj.BAT_PER?.To || ""} />
                                    </div>)}
                                    {betteryPr.LessEqual && modem.checktextBP && (<div className="mb-0  tw-col-span-3 checktext13" >
                                        <input type="text" className="form-control" name="filter_rssi_less_then" id="filter_rssi_less_then" placeholder="less than..." onChange={(e) => { setFilterObj({ ...filterObj, BAT_PER: { ...filterObj.BAT_PER, Value: e.target.value } }) }} value={filterObj.BAT_PER?.Value || ""} />
                                    </div>)}
                                    {betteryPr.GreaterEqual && modem.checktextBP && (<div className="mb-0  tw-col-span-3 checktext13" >
                                        <input type="text" className="form-control" name="filter_rssi_greater_then" id="filter_rssi_greater_then" placeholder="greter than..." onChange={(e) => { setFilterObj({ ...filterObj, BAT_PER: { ...filterObj.BAT_PER, Value: e.target.value } }) }} value={filterObj.BAT_PER?.Value || ""} />
                                    </div>)}
                                </div>

                                <div className="tw-grid tw-grid-cols-12 tw-w-full tw-items-center tw-gap-1">
                                    <div className="custom-control custom-checkbox tw-col-span-3">
                                        <input type="checkbox" id="checktextLS" name="Last_Seen" className="custom-control-input" value="lastssen" checked={modem.checktextLS} onChange={handleChangeS} />
                                        <label className="custom-control-label" htmlFor="checktextLS">Last seen</label>
                                    </div>

                                    {modem.checktextLS && (<div className="mb-0  tw-col-span-3 checktextLS lastssen" >
                                        <ReactSelect
                                            placeholder="Select Condition..."
                                            name={'Last_Seen'}
                                            options={lastSeenWiseOption}
                                            className="basic-multi-select"
                                            onChange={(e) => { setFilterObj({ ...filterObj, Last_Seen: { ...filterObj.Last_Seen, Last_Seen: e.value } }) }}
                                            value={lastSeenWiseOption?.filter((v) => filterObj?.Last_Seen?.Last_Seen == v?.value) || []}
                                        />
                                    </div>)}

                                    {modem.checktextLS && (<div className="mb-0  tw-col-span-4 checktextLS lastssen" >
                                        <ReactSelect
                                            placeholder="Select Condition..."
                                            name={'Last_Seen'}
                                            options={lastSeenWise}
                                            className="basic-multi-select"
                                            onChange={handleLastSeen}
                                            value={lastSeenWise?.filter((v) => Object.keys(filterObj?.Last_Seen || {})?.includes(v?.value)) || []}
                                        />
                                    </div>)}

                                    {last.LessEqual && modem.checktextLS && (<div className="mb-0  tw-col-span-2 checktext31" >
                                        <input type="text" className="form-control" name="filter_hours" id="filter_less_hours" placeholder="" onChange={(e) => { setFilterObj({ ...filterObj, Last_Seen: { ...filterObj.Last_Seen, Value: e.target.value } }) }} value={filterObj.Last_Seen?.Value || ""} />
                                    </div>)}

                                    {last.GreaterEqual && modem.checktextLS && (<div className="mb-0  tw-col-span-2 checktext31" >
                                        <input type="text" className="form-control" name="filter_hours" id="filter_greater_hours" placeholder="" onChange={(e) => { setFilterObj({ ...filterObj, Last_Seen: { ...filterObj.Last_Seen, Value: e.target.value } }) }} value={filterObj.Last_Seen?.Value || ""} />
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>

                <div className='tw-p-2 tw-flex tw-gap-2' >
                    <Button variant='destructive' type='button' disabled={gsmMapDataLoading} className='!tw-text-xs !tw-font-bold hover:tw-bg-red-400'
                        onClick={ongetAllMapDataInGSMFilter}>
                        {
                            gsmMapDataLoading ? <Loader className='tw-animate-spin !tw-text-base' /> : 'Submit'
                        }

                    </Button>
                    <Button variant='grey' disabled={gsmMapDataLoading} className='!tw-text-xs !tw-font-bold  tw-bg-gray-200 hover:tw-bg-gray-300'
                        onClick={onResetFilter}>
                        Reset
                    </Button>
                </div>

            </div>

            <div className={cn('tw-absolute tw-z-50 tw-top-0 tw-left-0  tw-h-[100dvh] tw-overflow-auto tw-w-[550px] tw-bg-gray-900 p-2', gsmInfo ? 'tw-visible' : 'tw-hidden')} >
                <div className='tw-py-2 tw-flex gap-2 tw-justify-startv px-3' >
                    <Button onClick={onCloseGSMConsumerInfo} className=' ' variant={"destructive"} size={"icon"} >
                        <X size={16} />
                    </Button>
                    <Button onClick={() => history(`/view_existing_consumer_gsm/${gsmInfo?.unique_id}`, {state: {} })} className=' ' variant={"grey"} size={"icon"} >
                        <SquareArrowOutUpRight size={16} />
                    </Button>
                </div>

                <div className="card-type" id="Device_Info_list">
                    <h4 className='tw-text-2xl tw-mb-4 px-3' id="c_consumer_name" style={{ color: "#fff" }}>{gsmInfo?.company_name || JSON.parse(gsmInfo?.address || "{}")?.address_compony_name}</h4>

                    <div className="card-body">
                        <ul className="nav nav-pills" id="myTab3" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="home-tab3" data-toggle="tab" href="#home3" role="tab" aria-controls="home" aria-selected="true">Modem Info</a>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="home3" role="tabpanel" aria-labelledby="home-tab">
                                <div className="card">
                                    <div className="card-header">
                                        <h4>Consumer Info</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-group mb-0">
                                            <label className="!tw-font-extrabold mr-1">Modem Serial No : </label>
                                            <label id="c_busniess_partner_number">{gsmInfo?.modem_serial_no}</label>
                                        </div>
                                        <div className="form-group mb-0 ">
                                            <label className="!tw-font-extrabold mr-1">Panel Serial No : </label>
                                            <label id="c_installtion_number">{gsmInfo?.panel_serial_no}</label>
                                        </div>
                                        <div className="form-group mb-0">
                                            <label className="!tw-font-extrabold mr-1">IMEI : </label>
                                            <label id="c_eui">{gsmInfo?.imei}</label>
                                        </div>
                                        <div className="form-group mb-0">
                                            <label className="!tw-font-extrabold mr-1">Consumer Unique ID : </label>
                                            <label id="c_unique_id">{gsmInfo?.unique_id}</label>
                                        </div>
                                        <div className="form-group mb-0">
                                            <label className="!tw-font-extrabold mr-1">Consumer Status : </label>
                                            <label id="c_consumer_status">{gsmInfo?.modem_status == 1 ? 'Active' : gsmInfo?.modem_status == -1 ? 'Pending Configration' : gsmInfo?.modem_status == 0 ? 'Inactive' : gsmInfo?.modem_status == 2 ? 'Under Maintenance' : 'Stand By'}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h4>Profile Info</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-group mb-0">
                                            <label className="!tw-font-extrabold mr-1">Account : </label>
                                            <label id="c_account">{gsmInfo?.ACCOUNT_NAME}</label>
                                        </div>
                                        <div className="form-group mb-0">
                                            <label className="!tw-font-extrabold mr-1">GA : </label>
                                            <label id="c_ga">{gsmInfo?.GA_NAME}</label>
                                        </div>
                                        <div className="form-group mb-0">
                                            <label className="!tw-font-extrabold mr-1">Cicle : </label>
                                            <label id="c_circle">{gsmInfo?.CIRCLE_NAME}</label>
                                        </div>
                                        <div className="form-group mb-0">
                                            <label className="!tw-font-extrabold mr-1">Division : </label>
                                            <label id="c_division">{gsmInfo?.DIVISION_NAME}</label>
                                        </div>
                                        <div className="form-group mb-0">
                                            <label className="!tw-font-extrabold mr-1">Sub Division : </label>
                                            <label id="c_sub_division">{gsmInfo?.SUBDIVISION_NAME}</label>
                                        </div>
                                        <div className="form-group mb-0">
                                            <label className="!tw-font-extrabold mr-1">Area : </label>
                                            <label id="c_area">{gsmInfo?.AREA_NAME}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h4>Modem Info</h4>
                                    </div>
                                    <div className="card-body">

                                        <div className="form-group mb-0">
                                            <label className="!tw-font-extrabold mr-1">RSSI : </label>
                                            {JSON.stringify(gsmInfo?.heartbeat_last_data || "{}")?.mmd_info_gsm_rssi >= 2 && JSON.stringify(gsmInfo?.heartbeat_last_data || "{}")?.mmd_info_gsm_rssi <= 9 ? (
                                                <>
                                                    <img src={signal2} style={{ height: '20px', width: "26px", border: '0', boxShadow: 'none', borderRadius: 'none' }} />
                                                    <span className="text-center">{JSON.stringify(gsmInfo?.heartbeat_last_data || "{}")?.mmd_info_gsm_rssi} </span>
                                                </>
                                            ) : JSON.stringify(gsmInfo?.heartbeat_last_data || "{}")?.mmd_info_gsm_rssi >= 10 && JSON.stringify(gsmInfo?.heartbeat_last_data || "{}")?.MMD_INFO_GSM_RSSI <= 14 ? (
                                                <>
                                                    <img src={signal3} style={{ height: '20px', width: "26px", border: '0', borderRadius: '0', boxShadow: 'none' }} />
                                                    <span className="text-center">{JSON.stringify(gsmInfo?.heartbeat_last_data || "{}")?.mmd_info_gsm_rssi} </span>
                                                </>
                                            ) : JSON.stringify(gsmInfo?.heartbeat_last_data || "{}")?.mmd_info_gsm_rssi >= 15 && JSON.stringify(gsmInfo?.heartbeat_last_data || "{}")?.mmd_info_gsm_rssi <= 19 ? (
                                                <>
                                                    <img src={signal4} style={{ height: '20px', width: "26px", border: '0', borderRadius: '0', boxShadow: 'none' }} />
                                                    <span className="text-center">{JSON.stringify(gsmInfo?.heartbeat_last_data || "{}")?.mmd_info_gsm_rssi} </span>
                                                </>
                                            ) : JSON.stringify(gsmInfo?.heartbeat_last_data || "{}")?.mmd_info_gsm_rssi >= 20 && JSON.stringify(gsmInfo?.heartbeat_last_data || "{}")?.mmd_info_gsm_rssi <= 31 ? (
                                                <>
                                                    <img src={signal5} style={{ height: '20px', width: "26px", border: '0', borderRadius: '0', boxShadow: 'none' }} />
                                                    <span className="text-center">{JSON.stringify(gsmInfo?.heartbeat_last_data || "{}")?.mmd_info_gsm_rssi} </span>
                                                </>
                                            ) : (
                                                "-"
                                            )}
                                            {/* <label id="c_rssi">24 (Excellent)</label> */}
                                        </div>
                                        <div className="form-group mb-0">
                                            <label className="!tw-font-extrabold mr-1">Last seen : </label>
                                            <label id="c_last_seen">{gsmInfo?.heartbeat_ts ? moment.utc(gsmInfo.heartbeat_ts).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : ''}</label>
                                        </div>
                                        <div className="form-group mb-0">
                                            <label className="!tw-font-extrabold mr-1">Latitude : </label>
                                            <label id="c_latitude">{gsmInfo?.latitude}</label>
                                        </div>
                                        <div className="form-group mb-0">
                                            <label className="!tw-font-extrabold mr-1">Longitude : </label>
                                            <label id="c_longitude">{gsmInfo?.longitude}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {
                (gsmDataLoading || gsmMapDataLoading || isLoadingPermission) ? <GoogleLoader bodyClassName={'tw-h-[100dvh]'} /> :
                    <>
                        <Button onClick={() => setIsFilterOpen(true)} className='tw-absolute tw-top-16 tw-right-4 tw-z-50 ' variant={"indigo"} size={"icon"} >
                            <Filter size={16} />
                        </Button>
                        <div className="map-inner" style={{ height: '100vh', width: "100%" }}>

                            <ArticlesMap
                                mapInfo={mapInfo}
                                GSMID={GSMID}
                                GSMData={GSMData}
                                defaultDate={defaultDate}
                                lastseen={lastSeen}
                                lastseenIndate={lastseenIndate}
                                onShowGSMConsumerInfo={onShowGSMConsumerInfo}
                                curentpos={{ lat: 21.727293496271507, lng: 75.5823933220894 }}
                                containerElement={<div style={{ height: '100%', width: '100%' }} />}
                                loadingElement={<div style={{ height: '100%', width: '100%' }} />}
                                mapElement={<div style={{ height: '100%', width: '100%' }} />}
                            />
                        </div>
                    </>
            }

        </>

    );
}

export const ArticlesMap = (props) => {

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyCsFo9yEz_k83max9KtfYAtECEHHuuXqcc',
        libraries: ['geometry', 'drawing', 'places'],
    });

    const [map, setMap] = useState(null);
    const [zoom, setZoom] = useState(5);
    const [jobDetail, setJobDetail] = useState({ activeMarker: {}, isOpen: false, Info: {} });
    const [center, setcenterPoint] = useState({ lat: props.curentpos.lat, lng: props.curentpos.lng });

    const onMarkerClicked = useCallback((data, marker) => {
        setJobDetail({
            activeMarker: { lat: data.latLng.lat(), lng: data.latLng.lng() },
            isOpen: true,
            Info: marker,
        });
    }, []);

    useEffect(() => {
        if (props.GSMData && Object.keys(props.GSMData).length > 0) {
            const newCenter = {
                lat: props.GSMData.latitude ? parseFloat(props.GSMData.latitude) : 0,
                lng: props.GSMData.longitude ? parseFloat(props.GSMData.longitude) : 0
            };
            setcenterPoint(newCenter);
            setZoom(15);
            setJobDetail({
                activeMarker: newCenter,
                isOpen: true,
                Info: props.GSMData,
            });
        }
    }, [props.GSMData]);

    const onToggleOpen = useCallback(() => {
        setJobDetail({ activeMarker: {}, isOpen: false, Info: {} });
        props.ModemInfoModem?.(false);
    }, [props.ModemInfoModem]);

    const onOpenInfoModel = useCallback((id) => {
        props.setModemInfoModem?.(id);
    }, [props.setModemInfoModem]);

    const getSecondsTodayBydate = useCallback((ts) => {
        const now = props.defaultDate;
        const then = moment.utc(ts).add(-moment().utcOffset(), "m").format("DD-MM-YYYY HH:mm:ss");
        return moment(now, "DD-MM-YYYY HH:mm:ss").diff(moment(then, "DD-MM-YYYY HH:mm:ss"), 'hours');
    }, [props.defaultDate]);

    const getClusterIconSize = useCallback((zoomLevel) => {
        if (zoomLevel <= 5) return 60;
        if (zoomLevel <= 7) return 50;
        if (zoomLevel <= 9) return 40;
        return 30;
    }, []);

    const clusterOptions = useMemo(() => ({
        gridSize: 24,
        maxZoom: 10,
        minimumClusterSize: 1,
        calculator: (markers) => ({
            text: markers.length.toString(),
            index: 1,
            title: `${markers.length}`
        }),
        styles: [
            {
                textColor: 'black',
                url: clusterIconImage,
                height: getClusterIconSize(zoom),
                width: getClusterIconSize(zoom),
                textSize: 12,
                anchorText: [0, 0],
                anchorIcon: [getClusterIconSize(zoom) / 2, getClusterIconSize(zoom) / 2]
            }
        ]
    }), [zoom, getClusterIconSize]);

    const onLoad = useCallback((map) => {
        setMap(map);
    }, []);

    const onZoomChanged = useCallback(() => {
        if (map) {
            setZoom(map.getZoom());
        }
    }, [map]);

    if (!isLoaded) return <GoogleLoader bodyClassName={'tw-h-[100dvh]'} />;

    const getMarkerIcon = (item) => {
        const isActive = jobDetail?.activeMarker?.lat === parseFloat(item?.latitude) &&
            jobDetail?.activeMarker?.lng === parseFloat(item?.longitude);

        if (isActive) return mapBlueicon;

        if (props?.lastseen && getSecondsTodayBydate(item?.heartbeat_ts) < props?.lastseen) {
            return mapicon;
        }

        if (props?.lastseenIndate?.start && props?.lastseenIndate?.end) {
            const heartbeatDate = moment.utc(item?.heartbeat_ts).add(-moment().utcOffset(), "m").format('DD-MM-YYYY');
            const startDate = moment(props.lastseenIndate.start, 'DD-MM-YYYY').format('DD-MM-YYYY');
            const endDate = moment(props.lastseenIndate.end, 'DD-MM-YYYY').format('DD-MM-YYYY');

            if (heartbeatDate >= startDate && heartbeatDate <= endDate) {
                return mapicon;
            }
        }
        return getSecondsTodayBydate(item?.heartbeat_ts) < 24 ? mapicon : mapRedicon;
    };

    return (
        <GoogleMap
            mapContainerStyle={props.containerElement.props.style}
            zoom={zoom}
            center={center}
            onLoad={onLoad}
            onZoomChanged={onZoomChanged}
        >
            {props?.mapInfo?.length > 0 && (
                // <MarkerClustererF options={clusterOptions}>
                // {(clusterer) =>
                props.mapInfo.map((item, id) => (
                    <MarkerF
                        key={id}
                        icon={{
                            url: getMarkerIcon(item),
                            scaledSize: new window.google.maps.Size(25, 25)
                        }}
                        // clusterer={clusterer}
                        options={{ optimized: false }}
                        onMouseOver={(data) => onMarkerClicked(data, item)}
                        onClick={() => props.onShowGSMConsumerInfo(item)}
                        position={{
                            lat: parseFloat(item?.latitude) || 0,
                            lng: parseFloat(item?.longitude) || 0
                        }}
                    />
                ))
                // }
                // </MarkerClustererF>
            )}

            {jobDetail?.activeMarker?.lat && jobDetail?.isOpen && (
                <InfoWindowF
                    position={{ lat: jobDetail.activeMarker.lat, lng: jobDetail.activeMarker.lng }}
                    onCloseClick={onToggleOpen}
                    options={{
                        pixelOffset: new window.google.maps.Size(0, -15)
                    }}
                >
                    <div className="detailCard">
                        <div className="d-flex main-div">
                            <div className="d-inline-block">
                                <h1 className="font-20">
                                    {jobDetail?.Info?.company_name || jobDetail?.Info?.address?.address_compony_name}
                                </h1>
                                <div className="form-group mb-0">
                                    <label className="f-800">Business Partner Number: </label>
                                    <label>{jobDetail?.Info?.BUSINESS_PARTNER_NUMBER}</label>
                                </div>
                                <div className="form-group mb-0">
                                    <label className="f-800">Installation Number: </label>
                                    <label>{jobDetail?.Info?.INSTALLATION_NUMBER}</label>
                                </div>
                                <div className="form-group mb-0">
                                    <label className="f-800">Customer ID: </label>
                                    <label>{jobDetail?.Info?.CUSTOMER_ID}</label>
                                </div>
                                <div className="form-group mb-0">
                                    <label className="f-800">Modem Serial Number: </label>
                                    <label>{jobDetail?.Info?.DEVICE_SERIAL_NO}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </InfoWindowF>
            )}
        </GoogleMap>
    );
};