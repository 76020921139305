import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import * as $ from "jquery";
import dt from "datatables.net-dt";
import { FilterIdWiseNewFilterGenerate, getgsmReportActivityLogs, getReportBytypeName, getReportDataInConsumerDetails, GetReportType, geturls3, getUsersList } from "../../Common/Utils";
import { AttachmanyUrl, BootstrapTooltip, ReportUrl } from "../../Common/common";
import moment from "moment";
import fileDownload from "js-file-download";
import { Users } from "react-feather";
import { Modal, Spin } from "antd";
import Pagination from "../../Common/Pagination";
import chroma from "chroma-js";
$.fn.DataTable = dt;
export default function Reports(props) {
	const [reportData, setReportData] = useState([]);
	const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
	const [loader, setLoader] = useState(false);
	const [ConsumerModel,setConsumerModel]=useState(false)

	const [btnText, setButtontext] = useState("Report Info");
	const [ReportType, setReportType] = useState("All");
	const [numberofPage, setnumberofPage] = useState(1);
	const [ReportTypes,setReportTypes]=useState([])
	const [ConsumerDetails,setConsumerDetails]=useState([])
	const [modalText,setmodalText]=useState('')
	const [sentDetails,setsentDetails]=useState({})
	const [historyDataLoader,sethistoryDataLoader]=useState(false)
	const model_tabes=['Report Info','Sent Details']
	const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece']
	const model_sub_sent_tabes=['Schedule','Manual']
	const [UserList,setUserList]=useState([])

	const onGetReportTypeGSM=async()=>{
		const data=await GetReportType({type:'GSM',report_cons_type:'slave'})
		if(data.success==true){
			setReportTypes(data?.data?.data?.map((item)=>({value:item?.unique_id,label:item?.front_type_name})))
		}else{

		}
	}
	const ongetUsersList = async () => {
        const data = await getUsersList()
        if (data.success === true) {
            setUserList(data.data.data)
        } else {
            console.log('something want wrong')
        }
    }
	const ongetReportUserData = async (ReportType) => {
		setLoader(true);
		const firstPageIndex = (currentPage - 1) * PageSize;
		const data = await getReportBytypeName(userInfo?.unique_id, userInfo?.role, ReportType, { start: firstPageIndex, end: PageSize });
		if (data.success === true) {
			setReportData(data.data.data);
			setnumberofPage(data.data.total_count)
			setLoader(false)
		} else {
			console.log("something want wrong");
			setLoader(false);
		}
	};

	const [PageSize, setPageSize] = useState(50);
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		ongetReportUserData(ReportType)
		onGetReportTypeGSM()
		ongetUsersList()
	}, [ReportType]);
	useEffect(() => {
		ongetReportUserData(ReportType)

	}, [currentPage, PageSize]);
	const onHandalebtn = (text) => {
		setButtontext(text);
		setReportType(text);
	};

	const onDownloadFiles = async (name) => {
		const data1=await geturls3({bucketName:'iot.memighty.com',objectKey:`GSM_REPORT_SCRIPT/${name}`, expiration:60})
		console.log(data1);
		var link = document.createElement("a");
		link.href = data1.data.data;
		link.download = name;
		link.click();
		// const myHeaders = new Headers();

		// const myRequest = new Request(data1.data, {
		// 	method: "GET",
		// 	headers: myHeaders,
		// 	mode: "cors",
		// 	cache: "default",
		// });
		// const data = await fetch(myRequest);
		// const blob = await data.blob();

		// if (data.status === 200) {
		// 	await fileDownload(blob, name);
		// }
	};
	

	const [info, setInfo] = useState({});
	const [ismodelFilter, setismodelFilter] = useState(false);
	const [InfoType, setInfoType] = useState("");
	const [ismodelFilterObj, setismodelFilterObj] = useState(false);
	const [FilterObject, setFilterObject] = useState({});
	const [SRNOReport, setSRNOReport] = useState({});
	const [btnSubText, setButtonSubtext] = useState("Schedule");

	const onChangeInfo = (info, add_by, add_at, TYPE, item) => {
		if (info) {
			const obj = JSON.parse(info || "{}");
			ongetActivityData(btnSubText=='Schedule'?item?.schedule_id:'',item?.unique_id,btnSubText=='Schedule'?'Schedule Reports':'Manual Sent Reports')
			setInfo({ ...item, ...obj, add_by: add_by, add_at: add_at });
			setInfoType(TYPE);
			setismodelFilter(true);
			setSRNOReport(item)
		}
	};

	
	useEffect(()=>{
		ongetActivityData(btnSubText=='Schedule'?info?.SCHEDULE_ID:'',info?.UNIQUE_ID,btnSubText=='Schedule'?'Schedule Reports':'Manual Sent Reports')
	},[btnSubText])

	const ongetActivityData = async (schedule_report_id,report_id,type) => {
        sethistoryDataLoader(true)
        const data = await getgsmReportActivityLogs({log_type:type,schedule_report_id:schedule_report_id,report_id:report_id })
        if (data.success === true) {
			if(type=='Schedule Reports'){
            setsentDetails(data.data.data[0])
			}else{
				setsentDetails(data.data.data)
			}
            sethistoryDataLoader(false)
			// sethistoryDataModale(true)
        } else {
            console.log('something want wrong')
            sethistoryDataLoader(false)
        }
    }

	const onFilterIdWiseNewFilterGenerate = async (filterObj) => {
		setismodelFilterObj(true);
		const data = await FilterIdWiseNewFilterGenerate({ Filter_Obj: filterObj });
		if (data.success == true) {
			setFilterObject(data.data.data);
		} else {
			console.log("something wait wrong");
		}
	};

	const onRefreshBtn = () => {
		ongetReportUserData(ReportType)
		// .then(() =>
		// 	setTimeout(function () {
		// 		$("#table-1").DataTable({ destroy: true, pageLength: 50, order: [[0, "desc"]] });
		// 		setLoader(false);
		// 	}, 1100)
		// );
	};

	const onGetConsumerDetails=async(col,SRNO,text)=>{
		setmodalText(text)
		const data=await getReportDataInConsumerDetails({Column:col,TableName:'gsm_reports',SRNO:SRNO})
		if(data.success==true){
			setConsumerDetails(JSON.parse(data?.data?.data||'{}'))
			setConsumerModel(true)
		}
	}
	return (
		<>
			<Modal title="Info" visible={ismodelFilter} footer="" onCancel={() => {setismodelFilter(false)
			setsentDetails([])}} width={800}>
			{model_tabes.map((item, id) => (
										 <button
											style={{
												backgroundColor: model_tabes.findIndex((data) => data === btnText) === id ? chroma(btnColor[id]).alpha(0.4).css() : '',
												color: model_tabes.findIndex((data) => data === btnText) === id ? chroma(btnColor[id]).darken(1.5).css() : btnColor[id],
												borderBottom: `1px solid ${btnColor[id]}`,
												boxShadow: model_tabes.findIndex((data) => data === btnText) === id ? `0 2px 8px -3px ${btnColor[id]}` : '',
											}}
											className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${model_tabes.findIndex((data) => data === btnText) === id ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}

											onClick={() => setButtontext(item)}>
											{item}
										</button>
									))}
				{btnText=='Report Info'?<div class="table-responsive">
					<table class="table table-striped" id="tabledata" style={{ width: "100%" }}>
						<thead>
							<tr>
								<th style={{ width: "50%" }}>Fields</th>
								<th style={{ width: "50%" }}>Value</th>
							</tr>
						</thead>
						<tbody>
							{InfoType == "allgsmconsumerlist"||InfoType == "allgsmconsumer" ?(
                                <>
                                    <tr>
                                        <td style={{ width: "50%" }}>Report Type</td>
                                        <td style={{ width: "50%" }}>{info?.Report_Type}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "50%" }}>Created BY</td>
                                        <td style={{ width: "50%" }}>{info?.CREATED_BY}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "50%" }}>Created At</td>
                                        <td style={{ width: "50%" }}>{moment.utc(info.CREATED_AT).format("DD/MM/YYYY hh:mm:ss A")}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "50%" }}>Generated AT</td>
                                        <td style={{ width: "50%" }}>{moment(info.Date_Time).format("DD/MM/YYYY hh:mm:ss A")}</td>
                                    </tr>
                                </>
                            ): (
								<>
									<tr>
										<td style={{ width: "50%" }}>Report Type</td>
										<td style={{ width: "50%" }}>{info?.NAME}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Start Date</td>
										<td style={{ width: "50%" }}>{moment(info.START_DATE).format("DD-MM-YYYY")}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>End Date</td>
										<td style={{ width: "50%" }}>{moment(info.END_DATE).format("DD-MM-YYYY")}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Created Date Time</td>
										<td style={{ width: "50%" }}>{moment.utc(info.CREATED_AT).format("DD/MM/YYYY hh:mm:ss A")}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Created By</td>
										<td style={{ width: "50%" }}>{info?.CREATED_BY}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Generated Date Time</td>
										<td style={{ width: "50%" }}>{moment(info.Date_Time).format("DD/MM/YYYY hh:mm:ss A")}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Total Selected Consumer</td>
										<td style={{ width: "50%" }}>{info?.Total_Consumer_Count}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Found Data Table</td>
										<td style={{ width: "50%",cursor:'pointer' }} onClick={()=>onGetConsumerDetails('REPORT_DAILY_EXIST',SRNOReport?.SRNO,'Found Data Table')}>{info?.Total_Daily_Consumer_Record_Exist}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Not Found Data Table</td>
										<td style={{ width: "50%",cursor:'pointer' }} onClick={()=>onGetConsumerDetails('REPORT_DAILY_NOT_EXIST',SRNOReport?.SRNO,'Not Found Data Table')}>{info?.Total_Daily_Consumer_Record_Not_Exist}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Records Exist</td>
										<td style={{ width: "50%",cursor:'pointer' }} onClick={()=>onGetConsumerDetails('REPORT_DATA_FOUND',SRNOReport?.SRNO,'Records Exist')}>{info?.Record_Found_from_Daily_Consumer}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Records Not Exist</td>
										<td style={{ width: "50%",cursor:'pointer' }} onClick={()=>onGetConsumerDetails('REPORT_DATA_NOT_FOUND',SRNOReport?.SRNO,'Records Not Exist')}>{info?.Record_Not_Found_from_Daily_Consumer}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Null Value</td>
										<td style={{ width: "50%",cursor:'pointer' }} onClick={()=>onGetConsumerDetails('REPORT_DATA_FOUND_VALUE_NULL',SRNOReport?.SRNO,'Null Value')}>{info?.Record_Found_Value_Null_from_Daily_Consumer}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>ScriptDecodeID Not Match</td>
										<td style={{ width: "50%",cursor:'pointer' }} onClick={()=>onGetConsumerDetails('SCRIPT_ID_NOT_MATCH',SRNOReport?.SRNO,'ScriptDecodeID Not Match')}>{info?.script_id_not_match}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Total Records Count</td>
										<td style={{ width: "50%" }}>{info?.Total_Records_Count}</td>
									</tr>
								</>
							) }
						</tbody>
					</table>
				</div>:''}
				{btnText=='Sent Details'?<>
				<div className="mt-2">
				{model_sub_sent_tabes.map((item, id) => (
					
										 <button
											style={{
												backgroundColor: model_sub_sent_tabes.findIndex((data) => data === btnSubText) === id ? chroma(btnColor[id]).alpha(0.4).css() : '',
												color: model_sub_sent_tabes.findIndex((data) => data === btnSubText) === id ? chroma(btnColor[id]).darken(1.5).css() : btnColor[id],
												borderBottom: `1px solid ${btnColor[id]}`,
												boxShadow: model_sub_sent_tabes.findIndex((data) => data === btnSubText) === id ? `0 2px 8px -3px ${btnColor[id]}` : '',
											}}
											className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${model_sub_sent_tabes.findIndex((data) => data === btnSubText) === id ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}

											onClick={() => setButtonSubtext(item)}>
											{item}
										</button>
				))}
									</div>
				{historyDataLoader&&<Spin size="small"/>}
				{ btnSubText=='Schedule'?Object.keys(JSON.parse(sentDetails?.response_json||'{}')).length > 0 ?<div class="table-responsive mt-3">
					<table class="table table-striped" id="tabledata" style={{ width: "100%" }}>
						<thead>
							<tr>
								<th>SRNO</th>
								<th>User Name</th>
								<th>User Email</th>
								<th>Sent Status</th>
								<th>Response</th>
							</tr>
						</thead>
						<tbody>
							{console.log(JSON.parse(sentDetails?.response_json||'{}'))}
							{Object.keys(JSON.parse(sentDetails?.response_json||'{}'))?.length>0&&Object.entries(JSON.parse(sentDetails?.response_json||'{}')).map(([keys,value],id)=>(<tr>
								<td>{id+1}</td>
								<td>{UserList.map(item=>item?.value===keys?`${item?.label}`:'')}</td>
								<td>{value?.envelope?.to}</td>
								<td>{value?.response?.error==true?<>Fail</>:<>success</>}</td>
								<td>{JSON.stringify(value)}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>:<div className='text-center tw-w-[100%] mt-3'><h4 >No Data Found</h4></div>:sentDetails.length>0?<div class="table-responsive mt-3">
					<table class="table table-striped" id="tabledata" style={{ width: "100%" }}>
						<thead>
							<tr>
								<th>SRNO</th>
								{/* <th>User Name</th> */}
								<th>User Email</th>
								<th>Sent Status</th>
								<th>Response</th>
							</tr>
						</thead>
						<tbody>
							
							{sentDetails?.length>0&&sentDetails.map((item,id)=>(<tr>
								<td>{id+1}</td>
								{/* <td>{UserList.map(item=>item?.value===keys?`${item?.label}`:'')}</td> */}
								<td>{JSON.parse(item?.response_json||'{}')?.envelope?.to}</td>
								<td>{JSON.parse(item?.response_json||'{}')?.response?.error==true?<>Fail</>:<>success</>}</td>
								<td>{item?.response_json}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>:<div className='text-center tw-w-[100%] mt-3'><h4 >No Data Found</h4></div>}
				</>:''}
			</Modal>
			<Modal title={modalText} visible={ConsumerModel} footer="" onCancel={() => {setConsumerModel(false)
			setConsumerDetails([])
			}} width={1000}>
				<div class="table-responsive">
					<table class="table table-striped" id="tabledata" style={{ width: "100%" }}>
						<thead>
							<tr>
								<th>SRNO</th>
								<th>UNIQUE ID</th>
								<th>DEVICE SERIAL NO</th>
								<th>Address Compony Name</th>
							</tr>
						</thead>
						<tbody>
							{ConsumerDetails.length>0&&ConsumerDetails.map((item,id)=>(<tr>
								<td>{id+1}</td>
								<td>{item?.unique_id}</td>
								<td>{item?.device_serial_no}</td>
								<td>{item?.address_compony_name}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</Modal>
			<Modal
				title="Filter Object"
				visible={ismodelFilterObj}
				footer=""
				onCancel={() => {
					setismodelFilterObj(false);
					setFilterObject({});
				}}
			>
				<pre>{JSON.stringify(FilterObject || "{}",null,2)}</pre>
			</Modal>
			
				{loader ? (
					<div className="main-content">
						<div class="loader"></div>
					</div>
				) : (
					""
				)}
				


				<section class="section">
					<section class="section-body">
						<div class="row">
							<div class="col-12">
								<div class="card">
									<div class="card-header p-2" style={{ justifyContent: "space-between" }}>
										<div class="form-group mb-0 mr-2">
											<select
												id="inputState"
												class="form-select form-control"
												name="modem_name"
												onChange={(e) => onHandalebtn(e.target.value)}
												value={ReportType}
												style={{ width: '300px', height: '35px', padding: '0px 15px' }}
											>
												<option value={'All'}>All</option>
													{ReportTypes?.map((item)=>(<option value={item?.value}>{item?.label}</option>))}
											</select>
										</div>
										<div className="d-flex 	">
											<BootstrapTooltip title='Refresh'><div><a
												class="btn btn-info text-white mt-1"
												onClick={onRefreshBtn}
												data-toggle="tooltip"
												data-placement="bottom"
												title=""
												data-original-title="Refresh"
											// style={{height: '32px',display: 'flex',flexDirection: 'column',justifyContent: 'center',borderRadius:'2px'}}
											>
												<i class="fa fa-refresh" aria-hidden="true"></i>
											</a></div></BootstrapTooltip>
										</div>
									</div>
									<div class="card-body">
										{reportData.length > 0 ? (
											<div className="table-responsive tw-h-screen tw-max-h-screen">
												<div id="table-1_wrapper" class="dataTables_wrapper no-footer">

													<table id="table_id" className="table border text-center table-borderless table-striped font-10">
														<thead className='tw-sticky tw-z-10 tw-top-0  tw-bg-gray-100'>
															<tr>
																<th className="text-center !tw-font-semibold" style={{ maxWidth: "100px" }}>
																	Unique No
																</th>
																<th className="!tw-font-semibold text-center">Report Name</th>
																<th className="!tw-font-semibold text-center" style={{ maxWidth: "100px" }}>
																	start_date
																</th>
																<th className="!tw-font-semibold text-center" style={{ maxWidth: "100px" }}>
																	end_date
																</th>
																<th className="!tw-font-semibold text-center" style={{ maxWidth: "100px" }}>
                                                                    Created At
                                                                </th>
																<th className="!tw-font-semibold text-center" style={{ maxWidth: "100px" }}>
                                                                    Created By
                                                                </th>
																<th className="!tw-font-semibold text-center">Schedule Id</th>
																<th className="!tw-font-semibold text-center" style={{ maxWidth: "100px" }}>
																	Status
																</th>
																<th className="!tw-font-semibold text-center">Trigger Type</th>
																<th className="!tw-font-semibold text-center">Download</th>
																<th className="!tw-font-semibold text-center">Info</th>
																<th className="!tw-font-semibold text-center">Filter Object</th>
															</tr>
														</thead>
														<tbody>
															{reportData &&
																reportData.map((item, id) => (
																	<tr style={{ height: "4rem" }} className="trheigth hover:tw-bg-[#D8D8D8]" key={id}>
																		<td className="text-center">{item?.srno}</td>
																		<td className="text-center">{item?.filename}</td>

																		<td className="text-center">
																			{item?.TYPE==='RTI1715060750'||item?.TYPE==='RTI1715060841'?moment(item?.start_date,'YYYY-MM').format("MM-YYYY"):item?.start_date
																				? moment.utc(item?.start_date).format("DD/MM/YYYY ")
																				: "N/A"}
																		</td>
																		<td className="text-center">
																			{item?.end_date
																				? moment.utc(item?.end_date).format("DD/MM/YYYY")
																				: "N/A"}
																		</td>
																		<td className="text-center">
                                                                            {item?.created_at
                                                                                ? moment.utc(item.created_at).format("DD/MM/YYYY hh:mm:ss A")
                                                                                : "N/A"}
                                                                        </td>
																		<td className="text-center">
                                                                            {item?.created_by}
                                                                        </td>
																		<td className="text-center">
                                                                            {item?.schedule_id}
                                                                        </td>
																		<td className="text-center">
																			<div
																				className={`badge ${item?.status == 1
																					? "badge-success"
																					: item?.status == 2 && moment(moment.utc().add(moment().utcOffset(), "m").format()).diff(moment(moment.utc(item.created_at).format()),'minutes') > 25
																						? "badge-danger"
																						: item?.status == 2
																							? "badge-warning"
																							: "badge-danger"
																					} badge-shadow text-white`}
																			>
																				{item?.status == 1
																					? "Success"
																					: item?.status == 2 && moment(moment.utc().add(moment().utcOffset(), "m").format()).diff(moment(moment.utc(item.created_at).format()),'minutes') > 25
																						? "Failed"
																						: item?.status == 2
																							? "Pending Report"
																							: "Failed"}
																			</div>
																		</td>
																		<td className="text-center">
																		<div
																				className={`badge ${item?.generated_by=='Schedule'
																					? "badge-primary": "badge-info"
																					} badge-shadow text-white`}
																			>
																				{item?.generated_by=='Schedule'?"Schedule":'Manual'}
																			</div>
																		</td>
																		<td className="text-center">
																		{item?.status == 1?	<a
																				class="btn-sm btn-warning text-white"
																				onClick={() => {
																					onDownloadFiles(item?.filename);
																				}}
																				download=""
																				target={"_blank"}
																			>
																				<i class="fa fa-download" aria-hidden="true"></i>
																			</a>:''}
																		</td>
																		<td className="text-center">
																			<a
																				onClick={() =>
																					onChangeInfo(
																						item?.info,
																						item?.created_by,
																						item?.created_at,
																						item?.type,
																						item
																					)
																				}
																				class="btn-sm btn-primary btn-sm font-14 text-center"
																			>
																				<i
																					class="fa fa-info-circle "
																					aria-hidden="true"
																					style={{ color: "white" }}
																				></i>
																			</a>
																		</td>
																		<td className="text-center">
																			<a
																				class="btn-sm btn-info btn-sm font-14 text-center"
																				onClick={() => onFilterIdWiseNewFilterGenerate(item?.report_filters)}
																			>
																				<i
																					class="
fas fa-filter"
																					aria-hidden="true"
																					style={{ color: "white" }}
																				></i>
																			</a>
																		</td>
																	</tr>
																))}
														</tbody>
													</table>
													

												</div>
												
											</div>

										) : (
											""
										)}
										<>
														<div className="d-flex justify-content-between">
															<div>Total Number Of Row {numberofPage}</div>
															<div class="dataTables_length" id="table_id_length">
																<label>
																	Show{" "}
																	<select
																		name="table_id_length"
																		aria-controls="table_id"
																		class=""
																		onChange={(e) => setPageSize(e.target.value)}
																		value={PageSize}
																	>
																		<option value={10}>10</option>
																		<option value={25}>25</option>
																		<option value={50}>50</option>
																		<option value={100}>100</option>
																	</select>{" "}
																	entries
																</label>
															</div>

															<Pagination
																className="pagination-bar"
																currentPage={currentPage}
																totalCount={numberofPage || 1}
																pageSize={PageSize}
																onPageChange={(page) => setCurrentPage(page)}
															/>
														</div>
													</>
									</div>
								</div>
							</div>
						</div>
					</section>
				</section>
		</>
	);
}


