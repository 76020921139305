import logo from './logo.svg';
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/style.css'
import './assets/css/components.css'
import './App.css'
import Header from './Components/Header/Header';
import moment from 'moment'
import { ToastContainer } from 'react-toastify';
import Login from './Components/Login/Login';
import Dashboard from './Components/Dashboard/Dashboard';
import PrivateRoutes from './Components/PrivateRoutes';
import User from './Components/User/User';
import AddUser from './Components/User/AddUser';
import AddAccount from './Components/Category/AddCategory/AddAccount';
import AddGa from './Components/Category/AddCategory/AddGa';
import AddCircle from './Components/Category/AddCategory/AddCircle';
import AddDivision from './Components/Category/AddCategory/AddDivision';
import AddSubdivison from './Components/Category/AddCategory/AddSubdevision';
import AddArea from './Components/Category/AddCategory/AddArea';
import Account from './Components/Category/Account';
import Ga from './Components/Category/Ga';
import Circle from './Components/Category/Circle';
import Division from './Components/Category/Division';
import Subdevision from './Components/Category/Subdevision';
import Area from './Components/Category/Area';
import AllModem from './Components/Modem/All';
import AddAll from './Components/Modem/AddModem/AddAll';
import AddFirmware from './Components/Modem/AddModem/AddFirmware';
import AddHardware from './Components/Modem/AddModem/AddHardware';
import AddMake from './Components/Modem/AddModem/AddMake';
import AddmodelNumber from './Components/Modem/AddModem/AddmodelNumber';
import Addsku from './Components/Modem/AddModem/Addsku';
import AddServerComm from './Components/Modem/AddModem/AddServerComm';
import AddType from './Components/Modem/AddModem/AddType';
import AddAllmeter from './Components/SlaveDrive/AddSlave/AddAllmeter';
import AddFirmwaremeter from './Components/SlaveDrive/AddSlave/AddFirmware';
import AddHardwarevmeter from './Components/SlaveDrive/AddSlave/AddHardware';
import AddMakemeter from './Components/SlaveDrive/AddSlave/Addmake';
import AddModelNumbermeter from './Components/SlaveDrive/AddSlave/Addmetrenumber';
import AddSoftwaremeter from './Components/SlaveDrive/AddSlave/AddSoftware';
import AddClass from './Components/SlaveDrive/AddSlave/AddClass';
import AddTypemeter from './Components/SlaveDrive/AddSlave/Addtype';
import AddCategory from './Components/SlaveDrive/AddSlave/AddCategory';
import AllMeter from './Components/SlaveDrive/All';
import Scriptcodeid from './Components/Scriptcodeid/Scriptcodeid';
import AddScriptcodeId from './Components/Scriptcodeid/AddScriptcodeId';
import Gsm from './Components/GSM/Gsm';
import AddModemDetails from './Components/GSM/AddModemDetails';
import AddGsm from './Components/GSM/AddGsm';
import GSM_deshboard from './Components/GSM/GSM_deshboard';
import SlaveScript from './Components/SlaveScript/SlaveScript';
import  AddSlaveScript  from './Components/SlaveScript/AddSlaveScript';
import AddRoles from './Components/Roles/AddRoles';
import Roles from './Components/Roles/Roles';
import Category from './Components/SlaveDrive/Category';
import MainTaskPage from './Components/Task2/MainTaskPage';
import Group from './Components/UsersGroup.js/Group';
import Reports from './Components/SlaveReport/Reports';
import SlaveReportsIndex from './Components/SlaveReport/SlaveReportsIndex';
import AddNotifyReceiver from './Components/Notification/AddNotifyReceiver';
import Notify from './Components/Notification/Notify';
import IOTDashboard from './Components/Dashboard/Dashboard';
import DashboardIndex from './Components/Dashboard/DashboardIndex';
import { useEffect, useState } from 'react';
import Userprofiles from './Components/Userprofiles/Userprofile';
import Tags from './Components/Tags/Tags';
import Addtags from './Components/Tags/Addtags';
import Map from './Components/MapView/Map';

function App() {
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ago",
      s: " %d seconds ago",
      ss: "%d seconds ago",
      m: "%d min ago",
      mm: "%d min ago",
      h: "an hours ago",
      hh: "%d hours ago",
      d: "a day ago",
      dd: "%d days ago",
      w: "a week ago",
      ww: "%d weeks ago",
      M: "a month ago",
      MM: "%d months ago",
      y: "a year ago",
      yy: "%d years ago"
    }
  });
  moment.relativeTimeThreshold('m', 60)
  moment.relativeTimeThreshold('s', 60)
  const location = useLocation()
  const userInfo = JSON.parse(window.localStorage.getItem('user_info')) || {}

  const [manuPermission, setmanuPermission] = useState([])
  const [isRouteLoading, setIsRouteLoading] = useState(true)

  const onHandalSwitch = (key) => {
    if (Object.keys(manuPermission || {}).length) {
      var obj_flag = false
      const updateData = (items) => items?.map(item => {
        if (item?.id === key && item?.view == true) {
          obj_flag = true
          return item.view
        }
        if (item.children) {
          return updateData(item.children)
        }
        return item.view;
      });
      updateData(manuPermission)
      return obj_flag
    }
  };

  useEffect(() => {
    if (location.pathname === '/gsm-map-view') {
      setIsRouteLoading(false)
    }
  }, [location.pathname])
  return (
    <>
     {location.pathname === '/login'|| location.pathname === '/gsm-map-view' ? "" : <Header setmanuPermission={(info) => setmanuPermission(info)} manuPermission={manuPermission} setIsRouteLoading={setIsRouteLoading}/>}
     <Routes>
     <Route exact path="/login" element={<Login />} />
     <Route exact element={<PrivateRoutes isRouteLoading={isRouteLoading}/>} >
     <Route path="*" element={<Navigate to="/" />} />
     <Route path="/gsm-map-view" element={<Map/>} />
        {onHandalSwitch("MENU1") || userInfo.role === 'ROLE1650614500708' ?<Route path="/GSM-Dashboard" element={<DashboardIndex />} />:''}
        {/* Users */}
        {onHandalSwitch("MENU4_1") || userInfo.role === 'ROLE1650614500708' ?<Route path="/add-users" element={<AddUser/>} exact />:''}
        {onHandalSwitch("MENU4_1") || userInfo.role === 'ROLE1650614500708' ?<Route path="/user" element={<User/>} exact />:''}
        {/* Roles */}
        {onHandalSwitch("MENU4_2") || userInfo.role === 'ROLE1650614500708' ?<Route path='/Roles' element={<Roles/>} exact />:''}
        {onHandalSwitch("MENU4_2") || userInfo.role === 'ROLE1650614500708' ? <Route path='/add-Roles' element={<AddRoles/>} exact />:''}

        {/* Category */}
        {onHandalSwitch("MENU2") || userInfo.role === 'ROLE1650614500708' ?<Route path="/add-account" element={<AddAccount/>} exact />:''}
        {onHandalSwitch("MENU2") || userInfo.role === 'ROLE1650614500708' ?<Route path="/add-ga/:accountId" element={<AddGa/>} exact />:''}
        {onHandalSwitch("MENU2") || userInfo.role === 'ROLE1650614500708' ?<Route path="/add-circle/:accountId/:gaId" element={<AddCircle/>} exact />:''}
        {onHandalSwitch("MENU2") || userInfo.role === 'ROLE1650614500708' ?<Route path="/add-division/:accountId/:gaId/:circleId" element={<AddDivision/>} exact />:''}
        {onHandalSwitch("MENU2") || userInfo.role === 'ROLE1650614500708' ?<Route path="/add-subdivision/:accountId/:gaId/:circleId/:divisionId" element={<AddSubdivison/>} exact />:''}
        {onHandalSwitch("MENU2") || userInfo.role === 'ROLE1650614500708' ?<Route path="/add-Area/:accountId/:gaId/:circleId/:divisionId/:subdivisionId" element={<AddArea/>} exact />:''}
        {onHandalSwitch("MENU2") || userInfo.role === 'ROLE1650614500708' ?<Route path="/account" element={<Account/>} exact />:''}
        {onHandalSwitch("MENU2") || userInfo.role === 'ROLE1650614500708' ?<Route path="/ga/:accountId" element={<Ga/>} exact />:''}
        {onHandalSwitch("MENU2") || userInfo.role === 'ROLE1650614500708' ?<Route path="/circle/:accountId/:gaId" element={<Circle/>} exact />:''}
        {onHandalSwitch("MENU2") || userInfo.role === 'ROLE1650614500708' ?<Route path="/division/:accountId/:gaId/:circleId" element={<Division/>} exact />:''}
        {onHandalSwitch("MENU2") || userInfo.role === 'ROLE1650614500708' ?<Route path="/subdivision/:accountId/:gaId/:circleId/:divisionId" element={<Subdevision/>} exact />:''}
        {onHandalSwitch("MENU2") || userInfo.role === 'ROLE1650614500708' ?<Route path="/area/:accountId/:gaId/:circleId/:divisionId/:subdivisionId" element={<Area/>} exact />:''}

        {/* modem driver */}
        {userInfo.role === 'ROLE1650614500708' ?<Route path="/allmodem" element={<AllModem/>} exact />:''}
        {userInfo.role === 'ROLE1650614500708' ?<Route path="/add-allmodem" element={<AddAll/>} exact />:''}
        {userInfo.role === 'ROLE1650614500708' ?<Route path="/add-firmware" element={<AddFirmware/>} exact />:''}
        {userInfo.role === 'ROLE1650614500708' ?<Route path="/add-Hardware" element={<AddHardware/>} exact />:''}
        {userInfo.role === 'ROLE1650614500708' ?<Route path="/add-make" element={<AddMake/>} exact />:''}
        {userInfo.role === 'ROLE1650614500708' ?<Route path="/add-modelNumber" element={<AddmodelNumber/>} exact />:''}
        {userInfo.role === 'ROLE1650614500708' ?<Route path="/add-sku" element={<Addsku/>} exact />:''}
        {userInfo.role === 'ROLE1650614500708' ?<Route path="/add-server-communication-protocol" element={<AddServerComm/>} exact />:''}
        {userInfo.role === 'ROLE1650614500708' ?<Route path="/add-type" element={<AddType/>} exact />:''}
        {/* Meter driver */}
        {userInfo.role === 'ROLE1650614500708' ?<Route path="/all-slaves/:CATID" element={<AllMeter/>} exact />:''}  
        {userInfo.role === 'ROLE1650614500708' ?<Route path="/all-category" element={<Category/>} exact />:''}  
        {userInfo.role === 'ROLE1650614500708' ?<Route path='/add-allmeter' element={<AddAllmeter/>} exact />:''}
        {userInfo.role === 'ROLE1650614500708' ?<Route path='/add-metercategory' element={<AddCategory/>} exact />:''}
        {userInfo.role === 'ROLE1650614500708' ?<Route path='/add-meterclass' element={<AddClass/>} exact />:''}
        {userInfo.role === 'ROLE1650614500708' ?<Route path='/add-metersoftware' element={<AddSoftwaremeter/>} exact />:''}
        {userInfo.role === 'ROLE1650614500708' ?<Route path='/add-metertype' element={<AddTypemeter/>} exact />:''}
        {userInfo.role === 'ROLE1650614500708' ?<Route path='/add-metermodelnumber' element={<AddModelNumbermeter/>} exact />:''}
        {userInfo.role === 'ROLE1650614500708' ?<Route path='/add-metermake' element={<AddMakemeter/>} exact />:''}
        {userInfo.role === 'ROLE1650614500708' ?<Route path='/add-meterhardwarev' element={<AddHardwarevmeter/>} exact />:''}
        {userInfo.role === 'ROLE1650614500708' ?<Route path='/add-meterfirmware' element={<AddFirmwaremeter/>} exact />:''}

        {userInfo.role === 'ROLE1650614500708' ?<Route path='/add-scriptcodeid' element={<AddScriptcodeId/>} exact />:''}
        {userInfo.role === 'ROLE1650614500708' ?<Route path='/scripcodeid' element={<Scriptcodeid/>} exact />:''}
        <Route path="/tasks" element={<MainTaskPage/>} exact />

        {/* GSM Consumer */}
        {onHandalSwitch("MENU3_1") || userInfo.role === 'ROLE1650614500708' ?<Route path='/All-GSM' element={<Gsm/>} exact />:''}
        {onHandalSwitch("MENU3_1") || userInfo.role === 'ROLE1650614500708' ?<Route path="/add-gsm-modem" element={<AddGsm/>} exact />:''}
        {onHandalSwitch("MENU3_1") || userInfo.role === 'ROLE1650614500708' ?<Route path='/AddModemDetails' element={<AddModemDetails/>} exact />:''}
        {(onHandalSwitch("MENU3_1") || userInfo.role === 'ROLE1650614500708') ?<Route path='/view_existing_consumer_gsm/:GSMID' element={<GSM_deshboard/>} exact />:''}

        {/* dashboard */}
        <Route path='/iotdashboard' element={<IOTDashboard/>} exact />

        {/* Slave Script Profile */}
        <Route path='/Slave-Script-profile' element={<SlaveScript/>} exact />
        <Route path='/add-Slave-Script-profile' element={<AddSlaveScript/>} exact />

        {/* User Groups */}
        {onHandalSwitch("MENU4_3") || userInfo.role === 'ROLE1650614500708' ?<Route path='/UsersGroup' element={<Group/>}></Route>:''}
        {/* Slaves Report */}
        {onHandalSwitch("MENU3_1") || userInfo.role === 'ROLE1650614500708' ?<Route path='/Slave-Report' element={<SlaveReportsIndex/>} exact />:''}
          {/*User Profile */}
          <Route path='/profiles' element={<Userprofiles />} exact />

          {/* Notification */}
        {onHandalSwitch("MENU6") || userInfo.role === 'ROLE1650614500708' ?<Route path='/Notification' element={<Notify/>}></Route>:''}
        {onHandalSwitch("MENU6") || userInfo.role === 'ROLE1650614500708' ?<Route path='/AddNotifyReceiver' element={<AddNotifyReceiver/>}></Route>:''}
        {onHandalSwitch("MENU7") || userInfo.role === 'ROLE1650614500708' ?<Route path='/tags' element={<Tags/>}></Route>:''}
        {onHandalSwitch("MENU7") || userInfo.role === 'ROLE1650614500708' ?<Route path='/add-tags' element={<Addtags/>}></Route>:''}
     </Route>
     </Routes>
     <ToastContainer position={"top-right"} autoClose={3000} limit={4} style={{zIndex:99999999999}}/>
    </>
  );
}

export default App;
