import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BootstrapTooltip } from "../../Common/common";
import { getAccount, getRolesById, getTags, insertBulkTagPermissions, insertTagPermissions } from "../../Common/Utils";
import TagsSelect from "../../Common/TagsSelect";
import { toast } from "react-toastify";
import { Modal } from "antd";
import ReactSelect from "../../Common/ui/react-select/react-select";
import { Button } from "../../Common/ui/button";
import { Plus } from "lucide-react";
import DataTable from 'datatables.net-dt';
import $ from "jquery";

// Initialize DataTable
$.fn.DataTable = DataTable;

export default function Tags() {
	// Combined state management
	const [state, setState] = useState({
		showBulkModal: false,
		tagsLoading: false,
		bulkTags: [],
		bulkCategories: [],
		tagsData: [],
		loader: true,
		permission: {},
		accountOptions: [],
		tagsOptions: []
	});

	const updateState = useCallback((newState) => {
		setState((prev) => ({ ...prev, ...newState }));
	}, []);

	const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
	const navigate = useNavigate();

	// Fetch roles and permissions
	const fetchRoles = useCallback(async () => {
		try {
			const data = await getRolesById(userInfo?.role);
			if (data.success) {
				const obj = data.data.data[0];
				updateState({
					permission: {
						...obj,
						ROLES_OTHER_PERMISSIONS: JSON.parse(obj.roles_other_permission || '{}')
					}
				});
			}
		} catch (error) {
			console.error('Error fetching roles:', error);
		}
	}, [userInfo?.role, updateState]);

	// Fetch tags data
	const fetchTagsData = useCallback(async () => {
		updateState({ loader: true });
		$(".loader").fadeOut("slow");

		try {
			const data = await getTags();
			if (data.success) {
				const tagsData = data.data.data;
				const options = tagsData.map(item => ({
					label: item.tag_name,
					value: item.unique_id
				}));

				updateState({
					tagsData,
					tagsOptions: options
				});

				// Initialize DataTable after data is loaded
				setTimeout(() => {
					const table = $("#table-1").DataTable({
						pageLength: 50,
						order: [[0, 'desc']],
						destroy: true // Destroy existing table before reinitializing
					});

					updateState({ loader: false });
					return table;
				}, 500);
			}
		} catch (error) {
			console.error('Error fetching tags:', error);
			updateState({ loader: false });
		}
	}, [updateState]);

	// Fetch account data
	const fetchAccountData = useCallback(async () => {
		try {
			const data = await getAccount(true);
			if (data.success) {
				const options = data.data.data.map(item => ({
					label: item.CATEGORY0,
					value: item.UNIQUE_ID
				}));
				updateState({ accountOptions: options });
			}
		} catch (error) {
			console.error('Error fetching accounts:', error);
		}
	}, [updateState]);

	// Initialize data on component mount
	useEffect(() => {
		// Fetch initial data
		fetchRoles();
		fetchAccountData();

		// Cleanup function for DataTable
		let dataTable;
		fetchTagsData().then(table => {
			dataTable = table;
		});

		return () => {
			// Destroy DataTable on component unmount
			if (dataTable) {
				dataTable.destroy();
			}
		};
	}, [fetchRoles, fetchAccountData, fetchTagsData]);

	// Handle tag permission updates
	const handleTagsChange = async (row, value) => {
		try {
			const response = await insertTagPermissions({
				tags_id: row.unique_id,
				category_ids: value
			});

			if (response.success) {
				toast.success('Tag permissions updated successfully');
				fetchTagsData();
			} else {
				toast.error('Error updating tag permissions');
			}
		} catch (error) {
			console.error('Error updating permissions:', error);
			toast.error('Error updating tag permissions');
		}
	};

	// Handle bulk permission updates
	const handleBulkSubmit = async () => {
		if (!state.bulkTags.length || !state.bulkCategories.length) {
			toast.error('Please select at least one tag and one category');
			return;
		}

		updateState({ tagsLoading: true });

		try {
			const response = await insertBulkTagPermissions({
				tags_ids: state.bulkTags.map(tag => tag.value),
				category_ids: state.bulkCategories.map(cat => cat.value)
			});

			if (response.success) {
				toast.success('Tag permissions updated successfully');
				await fetchTagsData();

				updateState({
					showBulkModal: false,
					bulkTags: [],
					bulkCategories: [],
					tagsLoading: false
				});
			} else {
				toast.error('Error updating bulk permissions');
				updateState({ tagsLoading: false });
			}
		} catch (error) {
			console.error('Error in bulk update:', error);
			toast.error('Error updating bulk permissions');
			updateState({ tagsLoading: false });
		}
	};

	// Close bulk modal
	const handleBulkModalClose = useCallback(() => {
		updateState({
			tagsLoading: false,
			showBulkModal: false,
			bulkTags: [],
			bulkCategories: []
		});
	}, [updateState]);

	// Navigate to edit page
	const navigateToEditTag = useCallback((item) => {
		navigate('/add-tags', {
			state: {
				...item,
				permission: state.permission
			}
		});
	}, [navigate, state.permission]);

	// Check if user has add permission
	const hasAddPermission = state.permission?.ROLES_OTHER_PERMISSIONS?.Tags?.add === true ||
		userInfo?.role === 'ROLE1650614500708';

	return (
		<>
			{/* Bulk permissions modal */}
			<Modal
				title="Bulk Add Tags Permissions"
				open={state.showBulkModal}
				onCancel={handleBulkModalClose}
				footer={null}
			>
				<div className="">
					<div className="tw-mb-4">
						<label className="tw-block tw-mb-1">Select Tags</label>
						<ReactSelect
							isMulti
							options={state.tagsOptions}
							value={state.bulkTags}
							onChange={(value) => updateState({ bulkTags: value })}
							placeholder="Select tags"
						/>
					</div>
					<div className="tw-mb-4">
						<label className="tw-block tw-mb-1">Select Accounts</label>
						<ReactSelect
							isMulti
							options={state.accountOptions}
							value={state.bulkCategories}
							onChange={(value) => updateState({ bulkCategories: value })}
							placeholder="Select accounts"
						/>
					</div>
					<div className="tw-flex tw-items-center tw-gap-2">
						<Button
							key="submit"
							onClick={handleBulkSubmit}
							variant="indigo"
							loading={state.tagsLoading}
						>
							Submit
						</Button>
						<Button
							variant="grey"
							type="button"
							size="sm"
							disabled={state.tagsLoading}
							className="tw-gap-x-2 tw-bg-transparent border hover:tw-bg-slate-100"
							onClick={handleBulkModalClose}
						>
							Cancel
						</Button>
					</div>
				</div>
			</Modal>

			{/* Main content */}
			<div className="main-content">
				{state.loader && (
					<div className="loader"></div>
				)}

				<div className="col-12 d-flex justify-content-between align-items-center pb-3 px-0">
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb p-0 mb-0">
							<li className="breadcrumb-item">
								<a href="/">
									<i className="fa fa-dashboard fa-lg"></i> Home
								</a>
							</li>
							<li className="breadcrumb-item active" aria-current="page">
								<a href="/" className="open_rightsidebar">
									<i className="fas fa-list"></i> Tags{" "}
								</a>
							</li>
						</ol>
					</nav>
				</div>

				<section className="section">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-header">
										<h4>Tags</h4>
										<div className="card-header-action tw-flex tw-items-center tw-gap-2">
											<Button
												variant="add"
												size="sm"
												onClick={() => updateState({ showBulkModal: true })}
											>
												<Plus size={16} />
												<span>Add Tags Permission</span>
											</Button>
											{hasAddPermission && (
												<Link to="/add-tags" className="btn btn-info text-white">
													<BootstrapTooltip title="Add">
														<span>
															<i
																className="fas fa-plus text-white"
																style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
															></i>
														</span>
													</BootstrapTooltip>
												</Link>
											)}
										</div>
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-striped" id="table-1">
												<thead>
													<tr>
														<th className="border" style={{ maxWidth: "50px" }}>Unique No</th>
														<th style={{ maxWidth: "300px" }} className="border">Tags Name</th>
														<th className="border">Account Permission</th>
														<th className="border">Tags Description</th>
														<th style={{ maxWidth: "100px" }} className="border">Status</th>
													</tr>
												</thead>
												<tbody>
													{state.tagsData.map((item, id) => (
														<tr
															style={{ height: "4rem" }}
															className="trheigth"
															key={id}
															onClick={() => navigateToEditTag(item)}
														>
															<td className="border">{item.srno}</td>
															<td className="border">
																<div
																	className="btn btn-icon icon-left"
																	style={{ background: item?.tag_color, color: "white" }}
																>
																	<i
																		className="fa fa-tag fa-lg"
																		style={{ marginRight: '4px' }}
																	></i>
																	{item.tag_name}
																</div>
															</td>
															<td
																className="border"
																onClick={(e) => e.stopPropagation()}
															>
																<TagsSelect
																	value={JSON.parse(item.permitted_category_ids || "[]")?.filter(tag => tag !== null) || []}
																	onChange={(value) => handleTagsChange(item, value)}
																	tagsOptions={state.accountOptions}
																/>
															</td>
															<td className="border">{item.description}</td>
															<td className="text-center border">
																<div className={`badge badge-${item.status === 1 ? 'success' : 'danger'} badge-shadow text-white`}>
																	{item.status === 1 ? 'Active' : 'Inactive'}
																</div>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
}