import { Modal } from 'antd'
import React from 'react'
import { Edit, Loader } from 'lucide-react'
import { Button } from '../../../Common/ui/button'

export default function CustomTabModal({
    TabID,
    onFiledEditHandler,
    isOpen,
    handleModelClose,
    TabDetails,
    setTabDetails,
    disabled,
    isLoading,
    onSubmit,
}) {
    return (
        <Modal
            style={{
                borderRadius: '12px',
                overflow: 'hidden',
                padding: "0px"
            }}
            className='my-add-modal'
            title={<div className='tw-flex tw-items-center tw-gap-x-1' >
                {!TabID ? "Add New Dashboard" : 'Update New Dashboard'}
                {
                    TabID && (
                        <Button onClick={onFiledEditHandler} variant='info' size='icon' className=''><Edit size={16} /></Button>
                    )
                }
            </div>}
            visible={isOpen}
            footer=''
            onCancel={handleModelClose}
        >
            <form className="">
                <div className="form-group">
                    <label>Dashboard Name</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <div className="input-group-text">
                                <i className="fas fa-table"></i>
                            </div>
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Dashboard Name"
                            name="tabName"
                            onChange={(e) => setTabDetails((prv) => ({ ...prv, name: e.target.value }))}
                            value={TabDetails?.name}
                            disabled={disabled || isLoading}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label>Dashboard Description</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <div className="input-group-text">
                                <i className="fas fa-table"></i>
                            </div>
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Dashboard Description"
                            name="tabName"
                            onChange={(e) => setTabDetails((prv) => ({ ...prv, description: e.target.value }))}
                            value={TabDetails?.description}
                            disabled={disabled || isLoading}
                        />
                    </div>
                </div>
            </form>

            <div>
                <Button variant='indigo' type='button' disabled={isLoading} className='!tw-text-xs !tw-font-bold mr-2 hover:tw-bg-indogo-600'
                    onClick={onSubmit}>
                    {isLoading ? <Loader size={14} className='tw-animate-spin !tw-text-base' /> : TabID ? 'Update' : 'Submit'}
                </Button>
                <Button variant='grey' disabled={isLoading} className='!tw-text-xs !tw-font-bold mr-2 tw-bg-gray-200 hover:tw-bg-gray-300'
                    onClick={handleModelClose}>
                    cancel
                </Button>
            </div>
        </Modal>
    )
}
