import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { DayPicker } from "react-day-picker";

import { buttonVariants } from "./button";
import { cn } from "../../lib/utils";

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  components: userComponents,
  ...props
}) {
  const defaultClassNames = {
    months: "tw-flex tw-flex-col tw-w-full tw-relative",
    month_caption: "tw-flex tw-justify-center tw-h-7 tw-mx-10 tw-relative tw-items-center",
    weekdays: "tw-flex tw-flex-row",
    weekday: "tw-text-muted-foreground tw-w-8 tw-font-normal tw-text-[0.8rem]",
    month: "tw-gap-y-4 tw-overflow-x-hidden tw-w-full",
    caption: "tw-flex tw-justify-center tw-pt-1 tw-relative tw-items-center",
    caption_label: "tw-text-sm tw-font-medium tw-truncate",
    button_next: cn(
      buttonVariants({
        variant: "outline",
        className:
          "tw-absolute tw-right-0 tw-h-7 tw-w-7 tw-bg-transparent tw-p-0 tw-opacity-50 hover:tw-opacity-100",
      })
    ),
    button_previous: cn(
      buttonVariants({
        variant: "outline",
        className:
          "tw-absolute tw-left-0 tw-h-7 tw-w-7 tw-bg-transparent tw-p-0 tw-opacity-50 hover:tw-opacity-100",
      })
    ),
    nav: "tw-flex tw-items-start",
    month_grid: "tw-mt-4 tw-mx-auto",
    week: "tw-flex tw-w-full tw-mt-2",
    day: "tw-p-0 tw-size-8 tw-h-8 tw-text-sm tw-flex-1 tw-flex tw-items-center tw-justify-center has-[button]:hover:!tw-bg-accent tw-rounded-md has-[button]:hover:aria-selected:!tw-bg-primary has-[button]:hover:tw-text-accent-foreground has-[button]:hover:aria-selected:tw-text-primary-foreground",
    day_button: cn(
      buttonVariants({ variant: "ghost" }),
      "tw-size-8 tw-p-0 tw-font-normal tw-transition-none hover:tw-bg-transparent hover:tw-text-inherit aria-selected:tw-opacity-100"
    ),
    range_start: "tw-day-range-start tw-rounded-s-md",
    range_end: "tw-day-range-end tw-rounded-e-md",
    selected:
      "tw-bg-indigo-500 tw-text-primary-foreground hover:!tw-bg-primary hover:tw-text-primary-foreground focus:tw-bg-primary focus:tw-text-primary-foreground",
    today: "tw-bg-accent tw-text-accent-foreground",
    outside:
      "tw-day-outside tw-text-muted-foreground tw-opacity-50 aria-selected:tw-bg-accent/50 aria-selected:tw-text-muted-foreground aria-selected:tw-opacity-30",
    disabled: "tw-text-muted-foreground tw-opacity-50",
    range_middle:
      "aria-selected:tw-bg-accent hover:aria-selected:!tw-bg-accent tw-rounded-none aria-selected:tw-text-accent-foreground hover:aria-selected:tw-text-accent-foreground",
    hidden: "tw-invisible",
    week_number: "tw-size-9 tw-p-0 tw-text-xs tw-font-medium tw-text-muted-foreground/80",
  };

  const mergedClassNames = Object.keys(defaultClassNames).reduce(
    (acc, key) => ({
      ...acc,
      [key]: classNames?.[key]
        ? cn(
          defaultClassNames[key],
          classNames[key],
        )
        : defaultClassNames[key],
    }), {});

  const defaultComponents = {
    Chevron: (props) => {
      if (props.orientation === "left") {
        return <ChevronLeftIcon size={16} {...props} aria-hidden="true" />;
      }
      return <ChevronRightIcon size={16} {...props} aria-hidden="true" />;
    },
  };

  const mergedComponents = {
    ...defaultComponents,
    ...userComponents,
  };

  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("tw-w-fit", className)}
      classNames={mergedClassNames}
      components={mergedComponents}
      {...props}
    />
  );
}

export { Calendar };
