import { Button } from '../../../Common/ui/button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuPortal, DropdownMenuShortcut, DropdownMenuSub, DropdownMenuSubContent, DropdownMenuSubTrigger, DropdownMenuTrigger } from '../../../Common/ui/dropdown-menu'
import React, { useState } from 'react'
import { Download, EllipsisVertical, Plus, Trash2 } from 'lucide-react'

export default function MoreOptionButton({
    activeTab,
    hasAddCardPermission,
    onAddCard,
    onRemoveCard,
    captureImage,
    newMenu = [],
    onExpandButton,
    onRefreshCard,
    buttonClassName,
}) {

    const [show, setShow] = useState(false);

    const options = [
        { value: 'png', label: 'Download in PNG' },
        { value: 'jpeg', label: 'Download in JPG' },
        ...newMenu
    ]

    const handleDownloadClick = (option) => {
        if (option?.value === 'Diagnostic Report') {
            option.event()
        } else {
            captureImage(option?.value)
        }
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant='indigo' size='sm' className='tw-px-1' >
                    <EllipsisVertical size={16} />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="tw-w-40">
                <DropdownMenuGroup>

                    {activeTab !== 'Custom Dashboard' && (hasAddCardPermission) && (
                        <DropdownMenuItem className='!tw-flex !tw-items-center tw-gap-x-2' onClick={onAddCard}>
                            <Plus size={14} />  Add Card
                        </DropdownMenuItem>
                    )}

                    {activeTab === 'Custom Dashboard' && (
                        <DropdownMenuItem className='!tw-flex !tw-items-center tw-gap-x-2' onClick={onRemoveCard}>
                            <Trash2 size={14} />  Remove Card
                        </DropdownMenuItem>
                    )}

                    {
                        captureImage &&
                        <DropdownMenuSub>
                            <DropdownMenuSubTrigger className='!tw-flex !tw-items-center tw-gap-x-2' > <Download size={14} /> Download</DropdownMenuSubTrigger>
                            <DropdownMenuPortal>
                                <DropdownMenuSubContent>
                                    {options?.map((option) => (
                                        <DropdownMenuItem onClick={() => handleDownloadClick(option)} >
                                            {option.label}
                                        </DropdownMenuItem>
                                    ))}
                                </DropdownMenuSubContent>
                            </DropdownMenuPortal>
                        </DropdownMenuSub>
                    }




                </DropdownMenuGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
