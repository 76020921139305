import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import * as XLSX from 'xlsx';
import socketio from "socket.io-client";
export const socket = socketio.connect('https://iotops.memighty.com:8001/');


export const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

   function convertToCSV(objArray) {
		var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
		var str = '';
	
		for (var i = 0; i < array.length; i++) {
			var line = '';
			for (var index in array[i]) {
				if (line != '') line += ','
	
				line += array[i][index];
			}
	
			str += line + '\r\n';
		}
	
		return str;
	}


  export const colorStyles = [
    {
        mainStyle: "!tw-text-green-600 hover:tw-bg-green-50 !tw-border-b-green-400",
        countStyle: "tw-bg-green-500"
    },
    {
        mainStyle: "!tw-text-yellow-600 hover:tw-bg-yellow-50 !tw-border-b-yellow-400",
        countStyle: "tw-bg-yellow-500"
    },
    {
        mainStyle: "!tw-text-red-600 hover:tw-bg-red-50 !tw-border-b-red-400",
        countStyle: "tw-bg-red-500"
    },
    {
        mainStyle: "!tw-text-indigo-600 hover:tw-bg-indigo-50 !tw-border-b-indigo-400",
        countStyle: "tw-bg-indigo-500"
    },
    {
        mainStyle: "!tw-text-orange-600 hover:tw-bg-orange-50 !tw-border-b-orange-400",
        countStyle: "tw-bg-orange-500"
    },
    {
        mainStyle: "!tw-text-fuchsia-600 hover:tw-bg-fuchsia-50 !tw-border-b-fuchsia-400",
        countStyle: "tw-bg-fuchsia-500"
    },
    {
        mainStyle: "!tw-text-blue-600 hover:tw-bg-blue-50 !tw-border-b-blue-400",
        countStyle: "tw-bg-blue-500"
    },
    {
        mainStyle: "!tw-text-rose-600 hover:tw-bg-rose-50 !tw-border-b-rose-400",
        countStyle: "tw-bg-rose-500"
    }
  ];

	export function exportCSVFile(headers, items, fileTitle) {
    if (headers) {
        items.unshift(headers);
    }

    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);

    var csv = convertToCSV(jsonObject);

    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

export function exportExcelDownload(data,filename){
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, `${filename}.xlsx`);
}

  export const AttachmanyUrl='https://ccmsnew.memighty.com:6001/uploadfiles/Attachmants/'
  export const ReportUrl='https://gums.memighty.com:7001/uploadfiles/Reports/'
  export const ReporLoratUrl='https://gums.memighty.com:7001/uploadfiles/Lorareport/'
  export const UserUrl='https://ccmsnew.memighty.com:6001/uploadfiles/Users/'
  export const MeterUrl='https://ccmsnew.memighty.com:6001/uploadfiles/Meterattachment/'
  export const TaskUrl='https://ccmsnew.memighty.com:6001/uploadfiles/Task/'


export const MENULIST = [
  {
    key: 1,
    name: 'Dashboard',
    id: 'MENU1',
    view: false,
    children: [
      {
        key: 11,
        name: 'All',
        id: 'MENU1_1',
        view: false
      },
      {
        key: 12,
        name: 'GSM Modem',
        id: 'MENU1_2',
        view: false
      },
      {
        key: 13,
        name: 'Lorawan Modem',
        id: 'MENU1_3',
        view: false
      }
    ]
  },
  {
    key: 2,
    name: 'Account',
    id: 'MENU2',
    view: false
  },
  {
    key: 3,
    name: 'Consumer',
    id: 'MENU3',
    view: false,
    children: [
      {
        key: 14,
        name: 'GSM Modem',
        id: 'MENU3_1',
        view: false
      },
      {
        key: 15,
        name: 'Lorawan Modem',
        id: 'MENU3_2',
        view: false
      }
    ]
  },
  {
    key: 4,
    name: 'Roles & Users',
    id: 'MENU4',
    view: false,
    children: [
      {
        key: 16,
        name: 'User',
        id: 'MENU4_1',
        view: false
      },
      {
        key: 17,
        name: 'Roles',
        id: 'MENU4_2',
        view: false
      },
      {
        key: 18,
        name: 'Users Group',
        id: 'MENU4_3',
        view: false
      }

    ]
  },
 
  {
    key: 6,
    name: 'Notification Manger',
    id: 'MENU6',
    view: false
  },
  {
    key: 7,
    name: 'Tags',
    id: 'MENU7',
    view: false
  },
  {
    key: 8,
    name: 'Release Notes',
    id: 'MENU8',
    view: false
  },
  {
    key: 9,
    name: 'Web Tabs',
    id: 'MENU9',
    view: false
  },
  {
    key: 10,
    name: 'Bad Data Analytics',
    id: 'MENU10',
    view: false
  }
]