export function getPieChartOptions(height, labels, colors, dataPointSelectionHandler, dataLabelFormatter) {
    return {
        chart: {
            height: height,
            type: 'pie',
            events: {
                dataPointSelection: dataPointSelectionHandler,
                dataPointMouseEnter: function (event) {
                    if (event.path) {
                        event.path[0].style.cursor = "pointer";
                    }
                }
            }
        },
        legend: {
            position: 'right',
            verticalAlign: 'bottom',
            width: 150
        },
        labels: labels,
        dataLabels: {
            enabled: true,
            textAnchor: "start",
            formatter: dataLabelFormatter || function (val) { return val; }, // Default formatter
            offsetX: 0,
        },
        responsive: [{
            breakpoint: 1500,
            options: {
                chart: {
                    width: "100%",
                },
                legend: {
                    position: 'right',
                    width: 150
                }
            }
        }, {
            breakpoint: 1300,
            options: {
                chart: {
                    width: "100%",
                    height: 380
                },
                legend: {
                    position: 'bottom',
                    width: "100%",
                    height: 100
                }
            }
        }, {
            breakpoint: 1100,
            options: {
                chart: {
                    height: 380
                },
                legend: {
                    position: 'bottom',
                    width: "100%",
                    height: 100
                }
            }
        }, {
            breakpoint: 700,
            options: {
                chart: {
                    height: 380
                },
                legend: {
                    position: 'bottom',
                    width: "100%",
                    height: 100
                }
            }
        }, {
            breakpoint: 500,
            options: {
                chart: {
                    height: 350
                },
                legend: {
                    show: false
                }
            }
        }],
        colors: colors
    };
}

export const sum = (arr) => {
    const result = arr.reduce((total, currentValue) => total = total + currentValue, 0);
    return result
}


export const coustomeDashboard = [
    { "CARD_NAME": "Power Source", "U_ID": "CARDID1" },
    { "CARD_NAME": "Temperature Range", "U_ID": "CARDID2" },
    { "CARD_NAME": "Temperature Data", "U_ID": "CARDID3" },
    { "CARD_NAME": "Modem Connectivity Status", "U_ID": "CARDID4" },
    { "CARD_NAME": "Offline Duration", "U_ID": "CARDID5" }
]